<template>
    <div class="scrollable" id="activity">
      <div class="icon-text title">
        <span class="icon has-text-success title">
          <i class="fas fa-stream"></i>
        </span>
        <span class="has-text-success">Activity</span>
      </div>
      <div
        :class="'columns ' + isUserActivity(activity.agentId)"
        v-for="(activity, index) in getSortedAtivities" :key="index">
        <div class="column is-1 with-inset-box-shadow">
          <span class="icon is-medium has-corner-position">
            <i v-if="activity.content[0].includes('created')"
              class="fas fa-lg fa-check-circle"
            ></i>
            <i v-else
              class="fas fa-lg fa-arrow-alt-circle-down"
            ></i>
          </span>
        </div>
        <div class="column is-10">
          <p>
            <b>{{ getAgentById(activity.agentId) }}</b>
          </p>
          <div class="is-flex is-justify-content-space-between">
            <div>
              <div v-for="(content, index) in activity.content" :key="index">
                - {{ content }}
              </div>
            </div>
            <span class="is-italic">
                {{getHumanDate(activity.createdAt, true)}}
            </span>
          </div>
          <hr>
        </div>
      </div>
    </div>
</template>

<script>
import { getAgentById, getHumanDate } from '@/common';
import { mapState } from 'vuex';

export default {
  name: 'Activity',
  methods: {
    getAgentById,
    getHumanDate,
    isUserActivity(agentId) {
      if (agentId === this.agent.id) {
        return 'has-text-success';
      }
      return '';
    },
  },
  props: {
    activities: Array,
  },
  computed: {
    ...mapState({
      allAgents: ({ agentData: { allAgents } }) => allAgents,
      agent: ({ agentData: { agent } }) => agent,
    }),
    getSortedAtivities() {
      const sortedActivities = this.activities;
      return sortedActivities.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    },
  },
};
</script>

<style scoped>

.columns {
    margin-left: 0.5em;
}

hr {
    margin: 1em 0;
}

.has-corner-position {
  transform: translate(calc(-50% + 0.5px), calc(50%));
}

.with-inset-box-shadow {
  box-shadow: inset 1px 0 #e3e8ee;
  padding: 0;
}

#activity > .columns:first-of-type .column.with-inset-box-shadow {
  margin-top: 1.4em;
}

#activity > .columns:first-of-type .column.with-inset-box-shadow .has-corner-position {
  transform: translate(calc(-50% + 0.5px), calc(-25%));
}

#activity > .columns:last-of-type .column.with-inset-box-shadow {
  margin-bottom: calc(10%);
}
</style>
