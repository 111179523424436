<template>
  <section class="section">
    <div v-if="isLoading">
      <Loading />
    </div>
    <div v-else>
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-three-quarters">

            <!-- Main container -->
            <nav class="level">
              <!-- Left side -->
              <div v-if="order && order.User" class="level-left">
                <div class="level-item">
                  <p class="subtitle is-5">
                    <router-link :to="`/candidate/${order.User.id}`">
                      <b>{{ order.User.firstName }}
                      {{ order.User.lastName }}({{ order.User.email }})</b>
                    </router-link>
                  </p>
                </div>
              </div>

              <!-- Right side -->
              <div v-if="order" class="level-right">
                <OrderAssigner />
                <Ticket />
              </div>
            </nav>

          </div>
        </div>
        <div class="columns is-centered has-text-left">
          <div class="column is-9" v-if="isTicketViewEnabled">
              <div v-if="order.Tickets && order.Tickets.length" class="block">
                <div v-for="(ticket, index) in order.Tickets"
                  :key="index" class="columns has-background-success-light">
                  <div class="column is-1">
                    <span class="tag is-primary">{{ getAgentById(ticket.agentId) }}</span>
                  </div>
                  <div class="column is-2">
                    <span>{{ ticket.status }}</span>
                  </div>
                  <div class="column is-9">
                    {{ ticket.comment }}
                    <br>
                    <span class="is-pulled-right">
                      <b-icon class="mr-1" icon="clock"></b-icon>
                      <b>{{ new Date(ticket.createdAt).toDateString() }}</b>
                      at {{ new Date(ticket.createdAt).toLocaleTimeString() }}
                    </span>
                  </div>
                </div>
              </div>
              <div v-else class="block">
                <b-message type="is-warning"
                  :title="getAgentById(ticket.agentId)"
                  aria-close-label="Close message"
                  has-icon
                >
                  No Tickets on this order
                </b-message>
              </div>
            </div>
        </div>

    <div v-if="order" class="columns is-mobile is-centered has-text-left">
      <div class="column is-9">
        <span class="title has-text-left">
          £<span>{{ amount/100 }}</span>
          <b-tag
           :type="order.Payment.Charge.status === 'created' ? 'is-success' : 'is-danger'"
           v-if="order.Payment && order.Payment.chargeId" class="mx-1">{{
            order.Payment.Charge.status === 'created' ? 'Payment Complete' : 'Refunded'
          }}</b-tag>
           <b-tag v-else class="mx-1'">
            payment not started
          </b-tag>
        </span>
        <MailTracking/>
        <hr>
        <div class="columns mt-5">
          <div class="column is-3">
            <b>Assigned</b>
            <br />
            <b-tag v-if="order.agentId" type="is-success is-light">
              {{ getAgentById(order.agentId) }}
            </b-tag>
          </div>
          <div class="column is-3">
            <b>Process By</b>
            <br />
            <b-tag v-if="order.backendAgentId" type="is-danger is-light">
              {{ getAgentById(order.backendAgentId) }}
            </b-tag>
          </div>
          <div class="column is-4">
            <b>Products</b>
            <br />
            <span v-for="(item, index) in order.items" :key="index">
              <b-tag class="mx-1">{{
                item
              }}</b-tag>
            </span>
          </div>
          <div class="column is-3">
            <b>Date</b>
            <br />
            <b>{{ getHumanDate(order.createdAt, true) }}</b>
          </div>
        </div>
      </div>
    </div>
        <div class="columns is-centered has-text-left">
          <div class="column is-three-quarters">
            <OrderContent v-if="order" :order="order" />
            <div v-else>Nothing Added yet</div>
          </div>
        </div>
        <div v-if="!(order && order.Payment && order.Payment.chargeId)">
          <hr>
        <div class="columns is-centered has-text-left">
          <div class="column is-three-quarters">
            <span class="title">Add To Order</span>
          </div>
        </div>
        <div class="columns is-centered has-text-left">
          <div class="column is-three-quarters">

            <b-collapse
              aria-id="contentIdForA11y1"
              class="panel"
              animation="slide"
              v-model="isTestOpen"
            >
              <template #trigger>
                <div
                    class="panel-heading"
                    role="button"
                    aria-controls="contentIdForA11y1">
                    <strong>Add Test</strong>
                  </div>
              </template>
              <Test />
            </b-collapse>

            <b-collapse
              aria-id="contentIdForA11y2"
              class="panel"
              animation="slide"
              v-model="isCourseOpen"
            >
              <template #trigger>
                <div
                    class="panel-heading"
                    role="button"
                    aria-controls="contentIdForA11y2">
                    <strong>Add Online Course</strong>
                  </div>
              </template>
              <OnlineCourse />
            </b-collapse>

            <b-collapse
              aria-id="contentIdForA11y3"
              class="panel"
              animation="slide"
              v-model="isOneDayCourseOpen"
            >
              <template #trigger>
                <div
                    class="panel-heading"
                    role="button"
                    aria-controls="contentIdForA11y3">
                    <strong>Add One Day Course</strong>
                  </div>
              </template>
              <OneDayCourse />
            </b-collapse>

            <b-collapse
              aria-id="contentIdForA11y4"
              class="panel"
              animation="slide"
              v-model="isCardOpen"
            >
              <template #trigger>
                  <div
                    class="panel-heading"
                    role="button"
                    aria-controls="contentIdForA11y4">
                    <strong>Add Card</strong>
                  </div>
              </template>
              <Card />
            </b-collapse>

            <b-collapse
              aria-id="contentIdForA11y5"
              class="panel"
              animation="slide"
              v-model="isProductOpen"
            >
              <template #trigger>
                  <div
                    class="panel-heading"
                    role="button"
                    aria-controls="contentIdForA11y5">
                    <strong>Add Product</strong>
                  </div>
              </template>
              <Product />
            </b-collapse>

            <div class="field is-horizontal">
              <div class="field-label is-normal has-text-left">
                <label class="label">Amount</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <b-field>
                    <b-numberinput
                      :value="getAmount"
                      @input="updateAmountValue"
                      step="0.01"
                    >
                    </b-numberinput>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import debounce from 'lodash/debounce';
import { mapActions, mapState } from 'vuex';

import Test from '@/components/Forms/Test.vue';
import Card from '@/components/Forms/Card.vue';
import OnlineCourse from '@/components/Forms/OnlineCourse.vue';
import OneDayCourse from '@/components/Forms/OneDayCourse.vue';
import Product from '@/components/Forms/Product.vue';
import Loading from '@/components/Loading.vue';

import OrderContent from '@/components/OrderContent.vue';
import Ticket from '@/components/Forms/Ticket.vue';
import MailTracking from '@/components/Forms/Order/MailTracking.vue';
import { getAgentById, createSnackbar, getHumanDate } from '@/common';
import OrderAssigner from './OrderAssigner.vue';

export default {
  name: 'Order',
  components: {
    Test,
    Loading,
    Card,
    Product,
    OnlineCourse,
    OrderContent,
    OrderAssigner,
    OneDayCourse,
    Ticket,
    MailTracking,
  },
  data: () => ({
    isLoading: false,
    isTestOpen: false,
    isCourseOpen: false,
    isOneDayCourseOpen: false,
    isCardOpen: false,
    isProductOpen: false,
    amountDisabled: true,
  }),
  watch: {
    async $route() {
      if (this.$route.query.o) {
        this.setOrderId(this.$route.query.o);
        await this.getOrder();
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    try {
      if (this.$route.query.o) {
        this.setOrderId(this.$route.query.o);
        await this.getOrder();
        this.setUserId(this.order.userId);
        return true;
      } if (this.$route.query.u) {
        this.resetOrder();
        this.setOrderId(undefined);
        this.setAmount(undefined);
        this.resetItems();

        if (
          this.user
        && this.user.id
        && this.user.id === this.$route.query.u
        ) {
          return null;
        }
        this.setUserId(this.$route.query.u);
        return this.getLeadDetails();
      }
    } catch (error) {
      this.$router.push({ name: 'Login' });
      throw error;
    } finally {
      this.isLoading = false;
    }
    return null;
  },
  methods: {
    ...mapActions([
      'setAmount', 'resetOrder', 'setItems', 'resetItems', 'setUserId',
      'getLeadDetails', 'setOrderId', 'getOrder', 'updateAmount',
    ]),
    getAgentById,
    createSnackbar,
    getHumanDate,
    updateAmountValue: debounce(async function updateAmountValue(amount) {
      try {
        await this.updateAmount(Math.round(amount * 100));
      } catch (error) {
        this.createSnackbar('Could not update amount.');
        throw error;
      }
    }, 2500),
    updateItems(e) {
      this.setItems(e);
    },
    unsetAmountDisabled() {
      this.amountDisabled = false;
    },
    updateOrder(e) {
      console.log(e);
      this.createSnackbar('Order has been updated');
    },
    viewTickets() {
      this.setViewTicket(true);
    },
  },
  computed: {
    ...mapState({
      amount: (state) => state.orderDetail.amount,
      items: (state) => state.orderDetail.items,
      order: (state) => state.orderDetail.order,
      userId: (state) => state.personalDetails.userId,
      user: ({ primary: { user } }) => user,
      allAgents: ({ agentData: { allAgents } }) => allAgents,
      isTicketViewEnabled: ({ orderDetail: { isTicketViewEnabled } }) => isTicketViewEnabled,
    }),
    getAmount() {
      return this.amount / 100;
    },
  },
};
</script>
