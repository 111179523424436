<template>
  <div v-if="order" class="mt-4">
    <div v-for="test in order.Tests" :key="test.id">
      <hr>
      <Test :test="test" />
    </div>
    <div v-for="course in order.OnlineCourses" :key="course.id">
      <hr>
      <OnlineCourse :course="course" />
    </div>
    <div v-for="course in order.OfflineCourseSeats" :key="course.id">
      <hr>
      <OneDayCourse :course="course" />
    </div>
    <div v-for="product in order.Products" :key="product.id">
      <hr>
      <Product :product="product" />
    </div>
    <div v-for="card in order.Cards" :key="card.id">
      <hr>
      <Card :card="card" />
    </div>
  </div>
</template>

<script>
import Test from '@/components/EditForms/Test.vue';
import OnlineCourse from '@/components/EditForms/OnlineCourse.vue';
import OneDayCourse from '@/components/EditForms/OneDayCourse.vue';
import Product from '@/components/EditForms/Product.vue';
import Card from '@/components/EditForms/Card.vue';

export default {
  name: 'OrderContent',
  components: {
    Test,
    OnlineCourse,
    OneDayCourse,
    Product,
    Card,
  },
  props: ['order'],
};
</script>
