<template>
    <span class="has-text-right is-pulled-right">
        <b-button icon-right="share" class="ml-2" type="is-primary is-light"
            @click="isComponentModalActive = true"
        >
            Send Tracking Link
        </b-button>

        <b-modal
        v-model="isComponentModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-label="Tracking Link Confirmation modal"
        aria-modal
        >
        <template #default="props">
            <div class="modal-card" style="width: auto">
            <section
                class="modal-card-body"
            >
                <p>Please confirm that you want to send the tracking link?</p>
            </section>
            <footer class="modal-card-foot">
                <b-button label="Confirm" type="is-success" expanded
                @click="() => sendTrackingLink(props)" />
                <b-button label="Cancel" type="is-danger" expanded @click="props.close" />
            </footer>
            </div>
        </template>
        </b-modal>
    </span>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { createSnackbar } from '@/common';

export default {
  data: () => ({
    isComponentModalActive: false,
  }),
  methods: {
    ...mapActions(['emailTracking']),
    createSnackbar,
    async sendTrackingLink(props) {
      try {
        await this.emailTracking(this.order.id);
        return this.createSnackbar('Tracking link has been sent.', true);
      } catch (error) {
        this.createSnackbar('Could not mail tracking link.');
        throw error;
      } finally {
        props.close();
      }
    },
  },
  computed: {
    ...mapState({
      order: (state) => state.orderDetail.order,
    }),
  },
};
</script>
