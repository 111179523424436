<template>
  <div class="field-container scrollable">
    <div class="icon-text title">
      <span class="icon has-text-info title">
        <i class="fas fa-comments"></i>
      </span>
      <span class="has-text-info">Comments</span>
    </div>
    <div v-for="comment in comments" class="field mt-5" :key="comment.id">
      <div class="columns">
        <div class="column is-3">
          <div v-if="comment.agentId === agent.id" class="icon-text has-text-info">
            <span class="icon has-text-info">
              <i class="fa fa-comment"></i>
            </span>
            <span><b>{{ getAgentById(comment.agentId) }}</b></span>
          </div>
          <div v-else class="icon-text">
            <span class="icon">
              <i class="fa fa-comment"></i>
            </span>
            <span><b>{{ getAgentById(comment.agentId) }}</b></span>
          </div>
          <span>
            {{ getHumanDate(comment.createdAt) }}
            at {{ new Date(comment.createdAt).toLocaleTimeString("en-GB") }}
          </span>
        </div>
        <div class="column is-9">
          <b-field>
            <b-message :type="comment.agentId === agent.id ? 'is-info' : ''"
              class="has-width-limited">{{
              comment.commentableType ? comment.content : comment.text
            }}</b-message>
          </b-field>
        </div>
      </div>
    </div>
    <div class="field is-horizontal mt-5">
      <div class="field-label is-normal has-text-info">
        <label class="label">
          <div class="icon-text has-text-info">
            <span class="icon has-text-info">
              <i v-if="localComment" class="fas fa-lg fa-comment-dots dynamic"></i>
              <i v-else class="fas fa-lg fa-comment"></i>
            </span>
            <span><b>{{ agent.name }}</b></span>
          </div>
          <br />
          <br />
          <b-button type="is-info is-light" @click.prevent="addComment"
            >Save Comment</b-button
          >
        </label>
      </div>
      <div class="field-body">
        <div class="field">
          <b-field>
            <b-input
              @keyup.shift.enter.native="addComment"
              @input="updateCommentLocalState"
              id="new-comment"
              ref="new-comment"
              type="textarea"
              placeholder="Press Shift+Enter to submit the comment..."
              validation-message="Please check the text"
              minlength="1"
              maxlength="1000"
              :value="localComment"
            ></b-input>
          </b-field>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { getHumanDate, getAgentById, createSnackbar } from '@/common';

export default {
  data: () => ({
    localComment: '',
  }),
  props: {
    comments: Array,
    entityId: String,
    type: String,
  },
  mounted() {
    const commentBox = document.getElementById('new-comment');
    commentBox.scrollIntoView(false);
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapState({
      agent: ({ agentData: { agent } }) => agent,
      allAgents: ({ agentData: { allAgents } }) => allAgents,
    }),
  },
  methods: {
    ...mapActions(['publishComment', 'publishGenComment']),
    getHumanDate,
    getAgentById,
    createSnackbar,
    updateCommentLocalState(e) {
      this.localComment = e;
    },
    async addComment() {
      if (this.localComment.length > 3) {
        try {
          if (this.type === 'user') {
            await this.publishComment(this.localComment);
          }

          if (
            this.type === 'card'
            || this.type === 'test'
            || this.type === 'online-course'
          ) {
            const payload = {
              content: this.localComment,
              commentableType: this.type,
              commentableId: this.entityId,
            };
            await this.publishGenComment(payload);
          }

          this.localComment = '';
          return this.createSnackbar(
            'Comment has been added',
            true,
            '#comment',
          );
        } catch (error) {
          console.error(error);
          return this.createSnackbar(
            'Error while adding comment',
            false,
            '#comment',
          );
        }
      }
      return null;
    },
  },
};
</script>

<style>
.has-width-limited {
  width: fit-content;
}
.scrollable {
  max-height: 500px;
  overflow-y: scroll;
  padding: 1.5em;
}

@keyframes wobble {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}
.dynamic {
  animation-duration: 1s;
  animation-name: wobble;
  animation-iteration-count: infinite;
}
</style>
