<template>
  <section class="section" id="add-card">
    <div v-if="cardCreated">
      <h1>Card: {{cardType}} created</h1>
    </div>
    <div v-if="!cardCreated">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-three-quarters">
            <h1 class="title">Card Details</h1>
            <h2 class="subtitle"
              >Candidate information has been added. Please select the preferred
                card which you want to procure.
              <strong>Make sure that you have the required qualifications.</strong>
            </h2>
          </div>
        </div>
        <div class="columns is-centered has-text-left">
          <div class="column is-three-quarters">
            <div class="field is-horizontal">
              <div class="field-label is-normal has-text-left">
                <label class="label">Card Type</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <b-field>
                    <b-autocomplete
                      v-model="name"
                      id="card-type"
                      ref="card-type"
                      :data="filteredDataArray"
                      placeholder="e.g. Green, Blue, Black"
                      icon="map-marker"
                      clearable
                      @select="updateCardType"
                    >
                      <template slot="empty">No results found</template>
                    </b-autocomplete>
                  </b-field>
                </div>
              </div>
            </div>

          <div class="field is-horizontal my-4">
            <div class="field-label is-normal has-text-left">
              <label class="label">Application Type</label>
            </div>
            <div class="field-body p-1">
              <div class="field">

                <b-field>
                  <b-radio
                  v-model="applicationType"
                  @input="updateApplicationType"
                  native-value="New"
                  type="is-success is-light is-outlined">
                      <span>New Card</span>
                  </b-radio>

                  <b-radio
                  @input="updateApplicationType"
                        v-model="applicationType"
                      native-value="Renew"
                      type="is-info is-light is-outlined">
                      <span>Renew Card</span>
                  </b-radio>

                  <b-radio
                      @input="updateApplicationType"
                        v-model="applicationType"
                      native-value="Lost/Stolen"
                      type="is-danger is-light is-outlined">
                      Lost/Stolen
                  </b-radio>
                </b-field>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
              <div class="field-label is-normal has-text-left">
                <label class="label">CITB Testing ID</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <b-input
                        placeholder="CITB000XYZ"
                        @input="updateTesingId"
                        id="testing-id"
                        ref="testing-id"
                        :value="testingId"
                        type="text"
                        minlength="1"
                        maxlength="20"
                      ></b-input>
                </div>
              </div>
            </div>

            <div v-if="applicationType !== 'New'" class="field is-horizontal">
              <div class="field-label is-normal has-text-left">
                <label class="label">Old Reg Number</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <b-input
                        @input="updateRegistrationNumber"
                        id="reg-no"
                        ref="reg-no"
                        :value="registrationNumber"
                        type="text"
                        minlength="1"
                        maxlength="20"
                      ></b-input>
                </div>
              </div>
            </div>

            <div class="field is-horizontal">
              <div class="field-label is-normal has-text-left">
                <label class="label">Trait</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <b-autocomplete
                    rounded
                    v-model="traitName"
                    :data="filteredTraitArray"
                    placeholder="Search by Trait/Occupation"
                    icon="magnify"
                    clearable
                    @select="updateTrait"
                  >
                    <template slot="empty">No results found</template>
                  </b-autocomplete>
                </div>
              </div>
            </div>
            <div class="field is-horizontal has-text-centered">
              <div class="field-body">
                <div class="field">
                  <b-field>
                    <b-button
                      @click="addCardForUser"
                      expanded
                      icon-right="arrow-circle-right"
                      type="is-success"
                    >Add Card</b-button>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { validate } from '@/common';
import { traits } from '@/assets/data';

export default {
  name: 'Card',
  data() {
    return {
      data: [
        'Red Provisional Card',
        'Red Trainee Card',
        'Red Apprentice Card',
        'Red Experienced Worker Card',
        'Red Technical Supervisor/Manager Card',
        'Green Labourer Card',
        'Blue Skilled Worker Card',
        'Gold Advanced Craft Card',
        'Black Manager Card',
        'Gold Supervisor Card',
        'SkillCard',
        'JIB Card',
        'ECS Card',
        'White – ACRIB Refrigerant Handler',
        'White Professionally Qualified Person',
        'White Academically Qualified Person',
        'Other Card',
      ],
      traitData: traits,
      name: '',
      traitName: '',
      cardCreated: false,
      applicationType: undefined,
    };
  },
  methods: {
    ...mapActions([
      'setCardType', 'setRenewal', 'addCard',
      'setTrait', 'getOrder', 'setApplicationType',
      'setTestingId', 'setRegistrationNumber',
    ]),
    updateCardType(e) {
      this.setCardType(e);
    },
    updateRenewal(e) {
      this.setRenewal(e);
    },
    updateTrait(e) {
      this.setTrait(e);
    },
    updateApplicationType(e) {
      this.setApplicationType(e);
    },
    updateTesingId(e) {
      this.setTestingId(e);
    },
    updateRegistrationNumber(e) {
      this.setRegistrationNumber(e);
    },
    updateComment(e) {
      this.setCardComment(e);
    },
    createSnackbar(message, success) {
      return this.$buefy.toast.open({
        message,
        type: success ? 'is-success' : 'is-warning',
        duration: 3500,
        container: 'section',
        position: 'is-top',
      });
    },
    async addCardForUser() {
      window.scrollTo(0, 0);

      const invalidFields = validate({
        'card-type': this.cardType,
      });

      if (!invalidFields.length) {
        try {
          await this.addCard();
          this.cardCreated = true;
          if (!this.$route.query.o) {
            this.$router.push(
              {
                name: 'Order',
                query: {
                  u: this.userId,
                  o: this.orderId,
                },
              },
            );
          }
          return this.createSnackbar('Card order has been added', true);
        } catch (error) {
          console.error('Error while adding course', error);
          return this.createSnackbar('Please enter correct details to continue');
        }
      } else {
        return this.createSnackbar(`Error in ${invalidFields[0]}`);
      }
    },
  },
  computed: {
    ...mapState({
      cardType: (state) => state.cardDetail.cardType,
      isRenewal: (state) => state.cardDetail.type,
      trait: (state) => state.cardDetail.trait,
      comment: (state) => state.cardDetail.comment,
      userId: (state) => state.personalDetails.userId,
      orderId: (state) => state.orderDetail.orderId,
      testingId: (state) => state.cardDetail.testingId,
      registrationNumber: (state) => state.cardDetail.registrationNumber,
    }),
    filteredDataArray() {
      return this.data.filter(
        (option) => option.toString().toLowerCase().indexOf(this.name.toLowerCase()) >= 0,
      );
    },
    filteredTraitArray() {
      return this.traitData.filter(
        (option) => option
          .toString()
          .toLowerCase()
          .indexOf(this.traitName.toLowerCase()) >= 0,
      );
    },
  },
};
</script>
