<template>
    <section class="section" id="online-course">
      <div v-if="productCreated">
        <h1>Product: {{name}} created</h1>
      </div>
      <div v-if="!productCreated">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-three-quarters">
              <h1 class="title">Product Details</h1>
            </div>
          </div>
          <div class="columns is-centered has-text-left">
            <div class="column is-three-quarters">
                <div class="field is-horizontal" id="name-field">
                    <div class="field-label is-normal has-text-left">
                        <label class="label">Product Name</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                        <b-field>
                            <b-input
                                @input="updateProductName"
                                id="product-name"
                                ref="product-name"
                                :value="name"
                                minlength="4"
                                maxlength="30"
                                type="text"
                            ></b-input>
                        </b-field>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal" id="short-name-field">
                    <div class="field-label is-normal has-text-left">
                        <label class="label">Short Name</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                        <b-field>
                            <b-input
                                @input="updateProductShortName"
                                id="product-short-name"
                                ref="product-short-name"
                                :value="shortName"
                                minlength="3"
                                maxlength="10"
                                type="text"
                            ></b-input>
                        </b-field>
                        </div>
                    </div>
                </div>
                <div class="field is-horizontal">
                  <div class="field-label is-normal has-text-left">
                  </div>
                  <div class="field-body">
                  <b-field>
                    <b-taglist>
                      <b-tag rounded>
                      <span class="is-clickable" @click="() => updateProductShortName('CITBBook')">
                        CITBBook</span>
                      </b-tag>
                      <b-tag rounded>
                        <span class="is-clickable" @click="() => updateProductShortName('AccExam')">
                          AccExam</span>
                        </b-tag>
                      <b-tag rounded>
                        <span class="is-clickable"
                        @click="() => updateProductShortName('CITBVideo')">
                          CITBVideo</span>
                      </b-tag>

                    </b-taglist>
                  </b-field>
                  </div>
                </div>

                <div class="field is-horizontal" id="detail-field">
                    <div class="field-label is-normal has-text-left">
                        <label class="label">Additional Detail</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                        <b-field>
                            <b-input
                                @input="updateDetail"
                                id="product-name"
                                ref="product-name"
                                type="textarea"
                                :value="detail"
                                minlength="4"
                                maxlength="100"
                            ></b-input>
                        </b-field>
                        </div>
                    </div>
                </div>
                <div class="field is-horizontal">
              <div class="field-label is-normal has-text-left">
                <label class="label">Amount</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <b-field>
                    <b-numberinput
                      :value="productAmount/100"
                      @input="updateAmount"
                      step="0.01"
                    >
                    </b-numberinput>
                  </b-field>
                </div>
              </div>
            </div>

              <div class="field is-horizontal has-text-centered">
                <div class="field-body">
                  <div class="field">
                    <b-field>
                      <b-button
                        @click="addProductForUser"
                        expanded
                        icon-right="arrow-circle-right"
                        type="is-success"
                      >Add Product</b-button>
                    </b-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>

<script>
import { mapActions, mapState } from 'vuex';
import { validate, createSnackbar } from '@/common';

export default {
  name: 'Product',
  data() {
    return {
      productCreated: false,
    };
  },
  methods: {
    ...mapActions([
      'setProductName',
      'setProductId',
      'setShortName',
      'setDetail',
      'addProduct',
      'setProductAmount',
      'getOrder',
    ]),
    createSnackbar,
    updateProductName(e) {
      this.setProductName(e);
    },
    updateProductShortName(e) {
      this.setShortName(e);
    },
    updateDetail(e) {
      this.setDetail(e);
    },
    updateAmount(e) {
      this.setProductAmount(e * 100);
    },
    async addProductForUser() {
      const invalidFields = validate({
        Name: this.name,
        'Short Name': this.shortName,
        Amount: this.productAmount,
        Detail: this.detail,
      });

      if (!invalidFields.length) {
        try {
          await this.addProduct();
          this.productCreated = true;
          if (!this.$route.query.o) {
            this.$router.push(
              {
                name: 'Order',
                query: {
                  u: this.userId,
                  o: this.orderId,
                },
              },
            );
          }

          return this.createSnackbar('Online Course has been added', true);
        } catch (error) {
          window.scrollTo(0, 0);
          console.error('Error while adding course', error);
          return this.createSnackbar(
            'Please enter correct details to continue',
          );
        }
      } else {
        window.scrollTo(0, 0);
        return this.createSnackbar(`Error in ${invalidFields[0]}`);
      }
    },
  },
  computed: {
    ...mapState({
      name: ({ productDetails: { name } }) => name,
      shortName: ({ productDetails: { shortName } }) => shortName,
      productAmount: ({ productDetails: { productAmount } }) => productAmount,
      detail: ({ productDetails: { detail } }) => detail,
      comment: ({ productDetails: { detail } }) => detail,
      userId: (state) => state.personalDetails.userId,
      orderId: (state) => state.orderDetail.orderId,
    }),
  },
};
</script>
