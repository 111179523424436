<template>
    <div class="container">
      <div class="columns is-centered has-text-left">
        <div class="column is-three-quarters">
          <nav class="level">
            <!-- Left side -->
            <div class="level-left">
              <div class="level-item">
                <h1 class="has-text-weight-bold is-size-3">
                  Product Details
                </h1>
              </div>
            </div>

            <!-- Right side -->
            <div class="level-right">
              <p class="level-item">
                <span class="has-text-weight-semibold is-italic">
                  {{
                    getHumanDate(product.createdAt)}}
                    at {{new Date(product.createdAt).toTimeString().split(' GMT')[0]
                  }}
                </span>
              </p>
            </div>
          </nav>
        </div>
      </div>
      <div class="columns is-centered has-text-left">
        <div class="column is-three-quarters">
          <div class="field is-horizontal">
            <div class="field-label is-normal has-text-left">
              <label class="label">Name</label>
            </div>
            <div class="field-body">
              <div class="field">
                <b-field>
                    <b-input
                    @input="updateProductName"
                    @focus="() => setProductId(product.id)"
                    id="product-name"
                    ref="product-name"
                    :value="product.name"
                    placeholder="Product Name"
                    type="text"
                    minlength="4"
                    maxlength="30"
                    icon-right="edit"
                    icon-right-clickable
                    @icon-right-click="unsetProductNameDisabled"
                    :disabled="productNameDisabled"
                  ></b-input>
                </b-field>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal has-text-left">
              <label class="label">Short Name</label>
            </div>
            <div class="field-body">
              <div class="field">
                <b-field>
                    <b-input
                    @input="updateShortName"
                    @focus="() => setProductId(product.id)"
                    id="short-name"
                    ref="short-name"
                    :value="product.shortName"
                    placeholder="Short Name"
                    type="text"
                    minlength="3"
                    maxlength="10"
                    icon-right="edit"
                    icon-right-clickable
                    @icon-right-click="unsetShortNameDisabled"
                    :disabled="shortNameDisabled"
                  ></b-input>
                </b-field>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal has-text-left">
              <label class="label">Detail</label>
            </div>
            <div class="field-body">
              <div class="field">
                <b-field>
                    <b-input
                    @input="updateDetail"
                    @focus="() => setProductId(product.id)"
                    id="product-detail"
                    ref="product-detail"
                    :value="product.detail"
                    placeholder="Product Detail"
                    type="textarea"
                    minlength="4"
                    maxlength="100"
                    icon-right="edit"
                    icon-right-clickable
                    @icon-right-click="unsetDetailDisabled"
                    :disabled="detailDisabled"
                  ></b-input>
                </b-field>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
              <div class="field-label is-normal has-text-left">
                <label class="label">Amount</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <b-field
                  >
                  <b-input
                      @focus="() => setProductId(product.id)"
                      icon="sterling-sign"
                      icon-pack="fas"
                      :value="product.amount/100"
                      @input="updateAmount"
                      type="number"
                      step="0.01"
                    >
                    </b-input>
                  </b-field>
                </div>
              </div>
            </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal has-text-left">
              <label class="label">Status</label>
            </div>
            <div class="field-body">
              <div class="field">
                <b-field>
                  <b-select
                    @input="updateStatus"
                    @focus="() => setProductId(product.id)"
                    :value="product.status"
                    id="product-status"
                    ref="product-status"
                    icon="desktop"
                    expanded
                  >
                  <option value="Open">Open</option>
                  <option value="To Be Booked">To Be Booked</option>
                    <option value="Booked">Booked</option>
                    <option value="Disputed">Disputed</option>
                    <option value="Cancelled">Cancelled</option>
                  </b-select>
                </b-field>
              </div>
            </div>
          </div>

          <div class="field has-text-centered">
            <b-button
              expanded
              icon-right="arrow-circle-right"
              type="is-success"
              @click.prevent="productSubmit"
            >
              Submit Product
            </b-button>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-full">
          <div class="field">
            <b-collapse :open="false" position="is-top" :aria-id="product.id">
              <template #trigger="props">
                <button class="button is-info is-light is-fullwidth">
                  <span class="icon">
                    <i v-if="!props.open" class="fas fa-chevron-down"></i>
                    <i v-else class="fas fa-chevron-up"></i>
                  </span>
                  <span>
                    {{
                      !props.open
                        ? "View Activity"
                        : "Hide Activity"
                    }}
                  </span>
                </button>
              </template>
              <div class="columns is-centered">
                <div class="column is-three-quarters">
                  <Activity :activities="product.Activities" />
                </div>
              </div>
            </b-collapse>
        </div>
        </div>
      </div>
    </div>
  </template>

<script>
import { mapActions } from 'vuex';
import { getHumanDate } from '@/common';
import Activity from '@/components/Activity.vue';

export default {
  data: () => ({
    updateProductFields: {},
    productNameDisabled: true,
    shortNameDisabled: true,
    detailDisabled: true,
  }),
  props: ['product'],
  components: {
    Activity,
  },
  methods: {
    ...mapActions(['updateProductDetail', 'setProductId']),
    getHumanDate,
    createSnackbar(message, success) {
      return this.$buefy.toast.open({
        message,
        type: success ? 'is-success' : 'is-warning',
        container: 'section',
        position: 'is-top',
      });
    },
    unsetProductNameDisabled() {
      this.productNameDisabled = false;
    },
    unsetShortNameDisabled() {
      this.shortNameDisabled = false;
    },
    unsetDetailDisabled() {
      this.detailDisabled = false;
    },
    updateProductName(e) {
      this.updateProductFields.name = e;
    },
    updateShortName(e) {
      this.updateProductFields.shortName = e;
    },
    updateDetail(e) {
      this.updateProductFields.detail = e;
    },
    updateAmount(e) {
      this.updateProductFields.amount = e * 100;
    },
    updateStatus(e) {
      this.updateProductFields.status = e;
    },
    async productSubmit() {
      try {
        await this.updateProductDetail(this.updateProductFields);
        return this.createSnackbar('Product Detail updated', true);
      } catch (error) {
        console.error(error);
        return this.createSnackbar('Error while updating details');
      } finally {
        window.scrollTo(0, 0);
      }
    },
  },
};
</script>
