<template>
  <section>
    <b-button
      label="Assign"
      type="is-danger"
      icon-left="tag"
      @click="isComponentModalActive = true"
    />

    <b-modal
      v-model="isComponentModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Order Assigner Modal"
      aria-modal
    >
      <template #default="props">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <span class="icon mr-4">
              <i class="fas fa-2x fa-users-cog"></i>
            </span>
            <p class="title is-4">Link Agent to Order</p>
          </header>
          <section
            class="modal-card-body"
          >
            <div
              class="block"
              v-for="(agentType, index) in Object.keys(groupAgentByType)"
              :key="index"
            >
              <h5 class="title is-5">{{ agentType }}</h5>
              <b-button
                v-for="agent in groupAgentByType[agentType]"
                :type="
                  agent.id === order.agentId ||
                  agent.id === order.backendAgentId
                    ? 'is-light is-success'
                    : 'is-light is-info'
                "
                :icon-left="
                  agent.id === order.agentId ||
                  agent.id === order.backendAgentId
                    ? 'check'
                    : ''
                "
                :key="agent.id"
                @click="() => assignOrderToAgent(agent.id)"
                expanded
                >{{ agent.name }}</b-button
              >
            </div>
          </section>
          <footer class="modal-card-foot">
            <b-button label="Close" expanded @click="props.close" />
          </footer>
        </div>
      </template>
    </b-modal>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { createSnackbar, getAgentById } from '@/common';

export default {
  name: 'OrderAssigner',
  data() {
    return {
      isComponentModalActive: false,
    };
  },
  methods: {
    ...mapActions(['updateOrderDetail', 'updateStripeChargeId']),
    createSnackbar,
    getAgentById,
    async attachStripeChargeId(chargeId) {
      if (chargeId.length !== 27) {
        return null;
      }

      try {
        await this.updateStripeChargeId(chargeId);
      } catch (error) {
        return this.createSnackbar(
          `Error while attaching charge ID: ${error.message}`,
        );
      }
      return true;
    },
    async assignOrderToAgent(agentId) {
      try {
        await this.updateOrderDetail({
          backendAgentId: agentId,
        });
        return this.createSnackbar('Successfully assigned', true);
      } catch (error) {
        return this.createSnackbar(
          `Error while assigning agent: ${error.message}`,
        );
      }
    },
  },
  computed: {
    ...mapState({
      order: ({ orderDetail: { order } }) => order,
      allAgents: ({ agentData: { allAgents } }) => allAgents,
    }),
    groupAgentByType() {
      const allEmployedAgents = this.allAgents.filter(({ employed }) => employed);
      return allEmployedAgents.reduce((acc, agent) => {
        if (agent.type === 'Admin' || agent.type === 'Backend') {
          if (acc[agent.type]) {
            acc[agent.type].push(agent);
          } else {
            acc[agent.type] = [agent];
          }
        }
        return acc;
      }, {});
    },
  },
};
</script>
