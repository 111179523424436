<template>
    <section>
      <span
        @click="isComponentModalActive = true"
      >
        <b-tag type="is-light is-info"
          aria-close-label="Close tag"
      >{{label}}
      </b-tag>
      </span>

        <b-modal
            v-model="isComponentModalActive"
            trap-focus
            :destroy-on-hide="false"
            aria-role="dialog"
            aria-label="PDF Viewer"
            aria-modal>
            <template #default>
                  <pdf :src="pdfSource"></pdf>
            </template>
        </b-modal>
    </section>
</template>

<script>
import pdf from 'vue-pdf';

export default {
  props: ['pdfSource', 'label'],
  components: {
    pdf,
  },
  data() {
    return {
      isComponentModalActive: false,
    };
  },
};
</script>
