<template>
  <div class="container">
    <div class="columns is-centered has-text-left">
      <div class="column is-three-quarters">
        <nav class="level">
          <!-- Left side -->
          <div class="level-left">
            <div class="level-item">
              <h1 class="has-text-weight-bold is-size-3">
                Offline Course Details
              </h1>
            </div>
          </div>
        </nav>
      </div>
    </div>
    <div class="columns is-centered has-text-left">
      <div class="column is-three-quarters">

        <div class="field is-horizontal">
          <div class="field-label is-normal has-text-left">
            <label class="label">Location</label>
          </div>
          <div class="field-body">
            <div class="field">
              <b-field>
                <b-select
                  @input="updateLocation"
                  :value="getLocation"
                  @focus="() => setOneDayCourseId(course.id)"
                  id="course-location"
                  ref="course-location"
                  icon="desktop"
                  expanded
                >
                  <option v-for="(location, i) in locations" :value="location.name" :key="i">
                    {{location.name}}
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal has-text-left">
            <label class="label">Course Date</label>
          </div>
          <div class="field-body">
            <b-field>
              <b-datepicker
                placeholder="Click to select date..."
                id="course-datetime"
                ref="course-datetime"
                icon="calendar-today"
                @focus="() => setOneDayCourseId(course.id)"
                @input="updateDatetime"
                :value="new Date(course.datetime)"
                :date-formatter="
                  (date) => new Intl.DateTimeFormat('in-IN').format(date)
                "
                trap-focus
              >
              </b-datepicker>
            </b-field>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal has-text-left">
            <label class="label">Status</label>
          </div>
          <div class="field-body">
            <div class="field">
              <b-field>
                <b-select
                  @input="updateCourseStatus"
                  @focus="() => setOneDayCourseId(course.id)"
                  :value="course.status"
                  id="course-status"
                  ref="course-status"
                  icon="desktop"
                  expanded
                >
                  <option value="Open">Open</option>
                  <option value="Booked">Booked</option>
                  <option value="Passed">Passed</option>
                  <option value="Retake Due">Retake Due</option>
                  <option value="Dispute">Dispute</option>
                  <option value="Cancelled">Cancelled</option>
                </b-select>
              </b-field>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal has-text-left">
            <label class="label">Provider</label>
          </div>
          <div class="field-body">
            <div class="field">
              <b-field>
                <b-select
                  @input="updateCourseProvider"
                  @focus="() => setOneDayCourseId(course.id)"
                  :value="course.provider"
                  id="course-status"
                  ref="course-status"
                  icon="desktop"
                  expanded
                >
                  <option value="Hurak">Hurak</option>
                </b-select>
              </b-field>
            </div>
          </div>
        </div>
        <div v-if="course.comment" class="field is-horizontal">
          <div class="field-label is-normal has-text-left">
            <label class="label">Comment</label>
          </div>
          <div class="field-body">
            <div class="field">
              <b-field>
                <b-input
                  @input="updateComment"
                  @focus="() => setOneDayCourseId(course.id)"
                  id="comment"
                  ref="comment"
                  :value="course.comment"
                  type="textarea"
                ></b-input>
              </b-field>
            </div>
          </div>
        </div>

        <div class="field has-text-centered">
          <b-button
            expanded
            icon-right="arrow-circle-right"
            type="is-success"
            @click.prevent="courseSubmit"
          >
            Submit Course
          </b-button>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-full">
        <div class="field">
          <b-collapse :open="false" position="is-top" :aria-id="course.id">
            <template #trigger="props">
              <button class="button is-info is-light is-fullwidth">
                <span class="icon">
                  <i v-if="!props.open" class="fas fa-chevron-down"></i>
                  <i v-else class="fas fa-chevron-up"></i>
                </span>
                <span>
                  {{
                    !props.open
                      ? "View Comments and Activity"
                      : "Hide Comments and Activity"
                  }}
                </span>
              </button>
            </template>
            <div class="columns is-centered">
              <div class="column is-three-quarters">
                <Comments
                  :entity-id="course.id"
                  :comments="course.GenComments"
                  type="online-course"
                />
              </div>
            </div>
            <hr />
            <div class="columns is-centered">
              <div class="column is-three-quarters">
                <Activity :activities="course.Activities" />
              </div>
            </div>
          </b-collapse>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { getHumanDate } from '@/common';
import Comments from '@/components/Comments.vue';
import Activity from '@/components/Activity.vue';

export default {
  data: () => ({
    updatedCourseFields: {},
  }),
  props: ['course'],
  components: {
    Comments,
    Activity,
  },
  computed: {
    ...mapState({
      locations: (state) => state.oneDayCourseDetails.locations,
    }),
    getLocation() {
      return this.locations?.find(({ id }) => id === this.course.locationId).name;
    },
  },
  methods: {
    ...mapActions(['updateOneDayCourseDetail', 'setOneDayCourseId']),
    getHumanDate,
    createSnackbar(message, success) {
      return this.$buefy.toast.open({
        message,
        type: success ? 'is-success' : 'is-warning',
        container: 'section',
        position: 'is-top',
      });
    },
    updateLocation(e) {
      this.updatedCourseFields.locationId = this.locations?.find(({ name }) => name === e).id;
    },
    updateDatetime(e) {
      this.updatedCourseFields.datetime = e;
    },
    updateCourseStatus(e) {
      this.updatedCourseFields.status = e;
    },
    updateCourseProvider(e) {
      this.updatedCourseFields.provider = e;
    },
    updateComment(e) {
      this.updatedCourseFields.comment = e;
    },
    async courseSubmit() {
      try {
        await this.updateOneDayCourseDetail(this.updatedCourseFields);
        return this.createSnackbar('Course Detail updated', true);
      } catch (error) {
        console.error(error);
        return this.createSnackbar('Error while updating details');
      } finally {
        window.scrollTo(0, 0);
      }
    },
  },
};
</script>
