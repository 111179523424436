<template>
    <section>
        <b-dropdown :triggers="['hover']" aria-role="list">
            <template #trigger>
                <b-button
                    type="is-success is-light"
                    class="ml-2"
                    icon-right="paperclip"
                >
                <b>{{(order.Tickets && order.Tickets.length) || '0'}}</b> Tickets
                  <!-- Add Ticket | <b>{{(order.Tickets && order.Tickets.length) || '0'}}</b> -->
                </b-button>
            </template>
            <b-dropdown-item v-if="order.Tickets && order.Tickets.length"
              aria-role="listitem">
              <span
                @click="viewTickets">
                View Tickets</span>
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem">
              <span @click="isComponentModalActive = true">Add Ticket</span>
            </b-dropdown-item>
        </b-dropdown>

        <b-modal
            v-model="isComponentModalActive"
            has-modal-card
            trap-focus
            :destroy-on-hide="false"
            aria-role="dialog"
            aria-label="Backend Assigner Modal"
            aria-modal>
            <template #default>
                <div class="modal-card">
                  <header v-if="error">
                    <b-message
                      type="is-danger"
                      has-icon
                    >
                        {{ error.message }}
                    </b-message>
                  </header>
                    <header class="modal-card-head">
                        <span class="icon mr-4">
                            <i class="fas fa-2x fa-sticky-note"></i>
                        </span>
                        <p class="title is-4">Add New Ticket</p>
                    </header>
                    <section class="modal-card-body">
                        <div class="block">
                            <b-button
                                type="is-light is-success"
                                class="mb-2"
                                expanded
                                :icon-left="order.agentId === assignedAgentId ? 'check' : ''"
                                @click="() => assignToTicket(order.agentId)"
                            >
                                {{ getAgentById(order.agentId)}}
                            </b-button>
                            <b-button
                                v-if="order.backendAgentId"
                                type="is-light is-info"
                                expanded
                                :icon-left="order.backendAgentId === assignedAgentId ? 'check' : ''"
                                @click="() => assignToTicket(order.backendAgentId)"
                            >
                                {{ getAgentById(order.backendAgentId)}}
                            </b-button>
                        </div>
                        <div class="block">
                            <b-field
                                label="Description"
                                label-position="on-border"
                            >
                                <b-input
                                  maxlength="1234"
                                  type="textarea"
                                  @input="setContent"
                                ></b-input>
                            </b-field>
                        </div>

                    </section>
                    <footer class="modal-card-foot">
                        <b-button
                            type="is-danger"
                            label="Save"
                            expanded
                            @click="submitTicket" />
                    </footer>
                </div>
            </template>
        </b-modal>
    </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { createSnackbar, getAgentById } from '@/common';

export default {
  name: 'Ticket',
  data() {
    return {
      isComponentModalActive: false,
      content: '',
      assignedAgentId: '',
      error: null,
      setTicketView: false,
    };
  },
  methods: {
    ...mapActions(['updateOrderDetail', 'addTicketToOrder', 'setViewTicket']),
    assignToTicket(agentId) {
      this.assignedAgentId = agentId;
    },
    setContent(e) {
      this.content = e;
    },
    viewTickets() {
      this.setViewTicket(true);
    },
    async submitTicket() {
      try {
        if (!this.assignedAgentId) {
          throw new Error('Ticket is not assigned to anyone. Unassigned tickets are as good as useless!');
        }
        await this.addTicketToOrder({
          content: this.content,
          agentId: this.assignedAgentId,
        });
        this.isComponentModalActive = false;
      } catch (error) {
        this.error = error;
      }
      return true;
    },
    createSnackbar,
    getAgentById,
  },
  computed: {
    ...mapState({
      order: ({ orderDetail: { order } }) => order,
      allAgents: ({ agentData: { allAgents } }) => allAgents,
    }),
  },
};
</script>
