<template>
  <section class="section" id="online-course">
    <div v-if="courseCreated">
      <h1>Course: {{type}} created</h1>
    </div>
    <div v-if="!courseCreated">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-three-quarters">
            <h1 class="title">Course Details</h1>
          </div>
        </div>
        <div class="columns is-centered has-text-left">
          <div class="column is-three-quarters">
            <div class="field is-horizontal">
              <div class="field-label is-normal has-text-left">
                <label class="label">Trade</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <b-autocomplete
                    id="trait"
                    ref="trait"
                    rounded
                    v-model="traitName"
                    :data="filteredTraitArray"
                    placeholder="Search by Trait/Occupation"
                    icon="magnify"
                    clearable
                    @select="updateCourseTrait"
                  >
                    <template slot="empty">No results found</template>
                  </b-autocomplete>
                </div>
              </div>
            </div>

            <div class="field is-horizontal">
              <div class="field-label is-normal has-text-left">
                <label class="label">Course Type</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <b-field>
                    <b-select
                      @input="updateType"
                      :value="type"
                      id="course-type"
                      ref="course-type"
                      icon="desktop"
                      expanded
                    >
                  <option
                    value="Level 1 Award (online)"
                  >
                    Level 1 Health and Safety in a Construction Environment (online)
                  </option>
                  <option value="SMSTS">SMSTS</option>
                  <option value="SSSTS">SSSTS</option>
                  <option value="NVQ Level 2">NVQ Level 2</option>
                  <option value="NVQ Level 3">NVQ Level 3</option>
                  <option value="NVQ Level 6">NVQ Level 6</option>
                    </b-select>
                  </b-field>
                </div>
              </div>
            </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal has-text-left">
            <label class="label">Final Assessment</label>
          </div>
          <div class="field-body">
              <b-field>
                <b-datepicker
                  placeholder="Click to select date..."
                  id="final-assessment-date"
                  ref="final-assessment-date"
                  icon="calendar-today"
                  @input="updateDate"
                  :value="finalAssessmentDate"
                  :min-date="minDate"
                  :max-date="maxDate"
                  :date-formatter="
                    (date) => new Intl.DateTimeFormat('in-IN').format(date)
                  "
                  trap-focus
                >
                </b-datepicker>
              </b-field>
            </div>
          </div>

            <div class="field is-horizontal has-text-centered">
              <div class="field-body">
                <div class="field">
                  <b-field>
                    <b-button
                      @click="addCourseForUser"
                      expanded
                      icon-right="arrow-circle-right"
                      type="is-success"
                    >Add Course</b-button>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { validate, createSnackbar } from '@/common';
import { traits } from '@/assets/data';

export default {
  name: 'OnlineCourse',
  data() {
    const today = new Date();

    return {
      traitData: traits,
      traitName: '',
      courseCreated: false,
      minDate: new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1),
      maxDate: new Date(today.getFullYear(), today.getMonth() + 2, today.getDate()),
    };
  },
  methods: {
    ...mapActions(['setCourseTrait', 'setCourseType', 'addCourse', 'setFinalAssessmentDate', 'getOrder']),
    createSnackbar,
    updateCourseTrait(e) {
      this.setCourseTrait(e);
    },
    updateType(e) {
      this.setCourseType(e);
    },
    updateDate(e) {
      this.setFinalAssessmentDate(e);
    },
    async addCourseForUser() {
      const invalidFields = validate({
        type: this.type,
      });

      if (!invalidFields.length) {
        try {
          await this.addCourse();
          this.courseCreated = true;
          if (!this.$route.query.o) {
            this.$router.push(
              {
                name: 'Order',
                query: {
                  u: this.userId,
                  o: this.orderId,
                },
              },
            );
          }

          return this.createSnackbar('Online Course has been added', true);
        } catch (error) {
          window.scrollTo(0, 0);
          console.error('Error while adding course', error);
          return this.createSnackbar(
            'Please enter correct details to continue',
          );
        }
      } else {
        window.scrollTo(0, 0);
        return this.createSnackbar(`Error in ${invalidFields[0]}`);
      }
    },
  },
  computed: {
    ...mapState({
      courseTrait: (state) => state.courseDetails.courseTrait,
      type: (state) => state.courseDetails.type,
      status: (state) => state.courseDetails.status,
      finalAssessmentDate: (state) => state.courseDetails.finalAssessmentDate,
      comment: (state) => state.courseDetails.comment,
      userId: (state) => state.personalDetails.userId,
      orderId: (state) => state.orderDetail.orderId,
    }),
    filteredTraitArray() {
      return this.traitData.filter(
        (option) => option
          .toString()
          .toLowerCase()
          .indexOf(this.traitName.toLowerCase()) >= 0,
      );
    },
  },
};
</script>
