<template>
  <div class="container">
    <div class="columns is-centered has-text-left">
      <div class="column is-three-quarters">
        <nav class="level">
          <!-- Left side -->
          <div class="level-left">
            <div class="level-item">
              <h1 class="has-text-weight-bold is-size-3">
                Online Course Details
              </h1>
            </div>
          </div>
        </nav>
      </div>
    </div>
    <div class="columns is-centered has-text-left">
      <div class="column is-three-quarters">
        <div class="field is-horizontal">
          <div class="field-label is-normal has-text-left">
            <label class="label">Trait</label>
          </div>
          <div class="field-body">
            <div class="field">
              <b-field>
                <b-select
                  @input="updateCourseTrait"
                  @focus="() => setCourseId(course.id)"
                  :value="course.trait"
                  id="course-trait"
                  ref="course-trait"
                  icon="desktop"
                  expanded
                >
                  <option value="Labourer, General Construction Operative">
                    Labourer, General Construction Operative
                  </option>
                  <option value="Carpenter and Joiner">
                    Carpenter and Joiner
                  </option>
                  <option value="General Construction Operative">
                    General Construction Operative
                  </option>
                  <option value="Painter and Decorator">
                    Painter and Decorator
                  </option>
                  <option value="Painter and Decorator">
                    Painter and Decorator
                  </option>
                  <option value="Bricklayer">Bricklayer</option>
                  <option value="Construction Contracting Surveyor">
                    Construction Contracting Surveyor
                  </option>
                  <option value="Plumber">Plumber</option>
                  <option value="Plasterer: Fibrous">Plasterer: Fibrous</option>
                  <option value="Plasterer: Solid">Plasterer: Solid</option>
                  <option value="Roofer - Specialist Leadworker">
                    Roofer - Specialist Leadworker
                  </option>
                  <option value="Architect">Architect</option>
                  <option value="Construction Team Leader">
                    Construction Team Leader
                  </option>
                  <option value="Construction Operative Kerbs & Channels">
                    Construction Operative Kerbs & Channels
                  </option>
                  <option value="Kitchen Bathroom Fitter">
                    Kitchen Bathroom Fitter
                  </option>
                  <option value="Steelfixer">Steelfixer</option>
                  <option value="Wall and Floor Tiler">
                    Wall and Floor Tiler
                  </option>
                  <option value="Civil Engineer">Civil Engineer</option>
                  <option value="Dry Liner: Fixer/ Finisher">
                    Dry Liner: Fixer/ Finisher
                  </option>
                  <option value="Construction Project Manager">
                    Construction Project Manager
                  </option>
                  <option
                    value="Construction Operative - Excavation and Reinstantement"
                  >
                    Construction Operative - Excavation and Reinstantement
                  </option>
                  <option value="Glazier">Glazier</option>
                  <option value="Construction Operative - Structural Concrete">
                    Construction Operative - Structural Concrete
                  </option>
                  <option value="Construction Operative Concrete Repairer">
                    Construction Operative Concrete Repairer
                  </option>
                  <option value="Construction Operative Drainage Construction">
                    Construction Operative Drainage Construction
                  </option>
                  <option value="Construction Operative Drainlayer">
                    Construction Operative Drainlayer
                  </option>
                  <option value="Demolition Manager">Demolition Manager</option>
                  <option value="Demolition Operative (Topman)">
                    Demolition Operative (Topman)
                  </option>
                  <option value="Demolition Site Operative (Trainee)">
                    Demolition Site Operative (Trainee)
                  </option>
                  <option
                    value="Demolition, Reclaimation and Refurbishment Operative"
                  >
                    Demolition, Reclaimation and Refurbishment Operative
                  </option>
                  <option value="Diamond Driller and Sawer">
                    Diamond Driller and Sawer
                  </option>
                  <option value="External Plasterer">External Plasterer</option>
                  <option value="Industrial Cleaner">Industrial Cleaner</option>
                  <option value="Service Duct Layer">Service Duct Layer</option>
                  <option value="Specialist Operative - Sprayed Concrete">
                    Specialist Operative - Sprayed Concrete
                  </option>
                  <option value="Specialist Operative: Concrete Repairer">
                    Specialist Operative: Concrete Repairer
                  </option>
                  <option value="Specialist Operative: Drainlayer">
                    Specialist Operative: Drainlayer
                  </option>
                  <option value="Specialist Operative: Highways Maintenance">
                    Specialist Operative: Highways Maintenance
                  </option>
                  <option value="Steel Erector">Steel Erector</option>
                  <option value="Steel Fabricator - Welder">
                    Steel Fabricator - Welder
                  </option>
                  <option value="Steel Fabricator - Plater">
                    Steel Fabricator - Plater
                  </option>
                  <option value="Tunneller - Hand Miner">
                    Tunneller - Hand Miner
                  </option>
                  <option value="Tunneller - Shaft Miner">
                    Tunneller - Shaft Miner
                  </option>
                  <option value="Tunnelling Machine Operator">
                    Tunnelling Machine Operator
                  </option>
                  <option value="Clerk of Works">Clerk of Works</option>
                  <option value="Construction Contracting Buyer">
                    Construction Contracting Buyer
                  </option>
                  <option value="Construction Contracting Planner">
                    Construction Contracting Planner
                  </option>
                  <option value="Construction Health and Safety Officer">
                    Construction Health and Safety Officer
                  </option>
                  <option value="Construction Site Manager (Contracting)">
                    Construction Site Manager (Contracting)
                  </option>
                  <option value="Cable TV System Installer">
                    Cable TV System Installer
                  </option>
                  <option value="Construction Site Supervisor (Contracting)">
                    Construction Site Supervisor (Contracting)
                  </option>
                  <option value="Chainman">Chainman</option>
                  <option value="Engineer (Trainee)">Engineer (Trainee)</option>
                  <option value="Process Plant & Equipment Installer">
                    Process Plant & Equipment Installer
                  </option>
                  <option value="Welding Inspector">Welding Inspector</option>
                  <option value="Asbestos Removal Operative">
                    Asbestos Removal Operative
                  </option>
                  <option value="Builders Cleaner">Builders Cleaner</option>
                  <option value="Cavity Wall Insulation Technician">
                    Cavity Wall Insulation Technician
                  </option>
                  <option value="Ceiling Fixer">Ceiling Fixer</option>
                  <option value="Cleaner">Cleaner</option>
                  <option value="Damp Proofing Operative">
                    Damp Proofing Operative
                  </option>
                  <option
                    value="Highways Maintenance - Excavation & Reinstatement"
                  >
                    Highways Maintenance - Excavation & Reinstatement
                  </option>
                  <option value="Highways Maintenance Operative: Drainage">
                    Highways Maintenance Operative: Drainage
                  </option>
                  <option
                    value="Highways Maintenance Operative: Flexible Pavement Construction"
                  >
                    Highways Maintenance Operative: Flexible Pavement
                    Construction
                  </option>
                  <option
                    value="Highways Maintenance Operative: Kerbs and Channels"
                  >
                    Highways Maintenance Operative: Kerbs and Channels
                  </option>
                  <option
                    value="Highways Maintenance Operative: Modular Pavement Construction"
                  >
                    Highways Maintenance Operative: Modular Pavement
                    Construction
                  </option>
                  <option
                    value="Highways Maintenance Operative: Modular Paving and Kerb Laying"
                  >
                    Highways Maintenance Operative: Modular Paving and Kerb
                    Laying
                  </option>
                  <option
                    value="Highways Maintenance Operative:
                            Shallow Drainage and Ducting Installation"
                  >
                    Highways Maintenance Operative: Shallow Drainage and Ducting
                    Installation
                  </option>
                  <option
                    value="Highways Maintenance Operative: Structural Concreting"
                  >
                    Highways Maintenance Operative: Structural Concreting
                  </option>
                  <option
                    value="Highways Maintenance: Shallow Drainage Installation Operative"
                  >
                    Highways Maintenance: Shallow Drainage Installation
                    Operative
                  </option>
                  <option value="Joint Taper">Joint Taper</option>
                  <option value="Landscape Operative - Exterior">
                    Landscape Operative - Exterior
                  </option>
                  <option value="Landscape Operative - Interior">
                    Landscape Operative - Interior
                  </option>
                  <option value="Landscaper">Landscaper</option>
                  <option value="Lift Maintenance Fitter">
                    Lift Maintenance Fitter
                  </option>
                  <option value="Lift Installer">Lift Installer</option>
                  <option value="On-site Steel Welder">
                    On-site Steel Welder
                  </option>
                  <option value="Piling Operative">Piling Operative</option>
                  <option
                    value="Plant Mechanic/ Construction Plant Maintenance and Repair"
                  >
                    Plant Mechanic/ Construction Plant Maintenance and Repair
                  </option>
                  <option value="Railway Engineer">Railway Engineer</option>
                  <option
                    value="Road Builder - High Friction Surfacing Operative - Manual"
                  >
                    Road Builder - High Friction Surfacing Operative - Manual
                  </option>
                  <option
                    value="Road Builder - High Friction Surfacing Operative - Machine"
                  >
                    Road Builder - High Friction Surfacing Operative - Machine
                  </option>
                  <option value="Road Builder - Retexturing">
                    Road Builder - Retexturing
                  </option>
                  <option value="Road Building: Planer">
                    Road Building: Planer
                  </option>
                  <option value="Road Recycling Operative">
                    Road Recycling Operative
                  </option>
                  <option value="Roof Slater and Tiler - New Roof">
                    Roof Slater and Tiler - New Roof
                  </option>
                  <option value="Roof Slater and Tiler - Re Roof">
                    Roof Slater and Tiler - Re Roof
                  </option>
                  <option value="Service Lift Engineer">
                    Service Lift Engineer
                  </option>
                  <option value="Shopfitter Sitefixer">
                    Shopfitter Sitefixer
                  </option>
                  <option value="Site Logistics - Plant">
                    Site Logistics - Plant
                  </option>
                  <option value="Stud Welder">Stud Welder</option>
                  <option value="Telecoms Cable Layer">
                    Telecoms Cable Layer
                  </option>
                  <option value="Tower Crane Erector">
                    Tower Crane Erector
                  </option>
                  <option value="Wet Pour Rubber Surface Operative">
                    Wet Pour Rubber Surface Operative
                  </option>
                  <option value="Wood Machinist">Wood Machinist</option>
                  <option value="Building Control Surveyor">
                    Building Control Surveyor
                  </option>
                  <option value="Building Maintenance & Estates Supervisor">
                    Building Maintenance & Estates Supervisor
                  </option>
                  <option value="Contracts Manager">Contracts Manager</option>
                  <option value="Demolition Engineer">
                    Demolition Engineer
                  </option>
                  <option value="Property Manager">Property Manager</option>
                  <option value="Quantity Surveyor">Quantity Surveyor</option>
                  <option value="Senior Construction Site Manager">
                    Senior Construction Site Manager
                  </option>
                  <option value="Surveying Assistant (General Practice)">
                    Surveying Assistant (General Practice)
                  </option>
                  <option value="Transportation Assistant">
                    Transportation Assistant
                  </option>
                  <option value="Access Flooring Operative">
                    Access Flooring Operative
                  </option>
                  <option value="Aerial and Satellite Installer">
                    Aerial and Satellite Installer
                  </option>
                  <option value="Aerial Rescue">Aerial Rescue</option>
                  <option value="AHU Installer">AHU Installer</option>
                  <option value="Air Compressor Engineer">
                    Air Compressor Engineer
                  </option>
                  <option value="Air Testing of Buildings Operative">
                    Air Testing of Buildings Operative
                  </option>
                  <option value="Aluminium TIG Welding & Fabrication">
                    Aluminium TIG Welding & Fabrication
                  </option>
                  <option value="Amenity Manager">Amenity Manager</option>
                  <option value="Amenity Supervisor">Amenity Supervisor</option>
                  <option value="Amenity Worker">Amenity Worker</option>
                  <option value="Ansell Fire Suppression System Installer">
                    Ansell Fire Suppression System Installer
                  </option>
                  <option value="Antenna Systems Installer">
                    Antenna Systems Installer
                  </option>
                  <option value="Anti Graffiti Finishing Operative">
                    Anti Graffiti Finishing Operative
                  </option>
                  <option value="Arborist Manager">Arborist Manager</option>
                  <option value="Arborist Supervisor">
                    Arborist Supervisor
                  </option>
                  <option value="Arborist Worker - Chainsaw">
                    Arborist Worker - Chainsaw
                  </option>
                  <option value="Archaeologist">Archaeologist</option>
                  <option value="Archaeologist Technician">
                    Archaeologist Technician
                  </option>
                  <option value="Architect">Architect</option>
                  <option
                    value="Architectural Lighting Control Systems Installer"
                  >
                    Architectural Lighting Control Systems Installer
                  </option>
                  <option value="Architectural Metalwork Paint Sprayer">
                    Architectural Metalwork Paint Sprayer
                  </option>
                  <option value="Architectural Technologist">
                    Architectural Technologist
                  </option>
                  <option value="Asbestos Analyst - 4 Stage Clearance">
                    Asbestos Analyst - 4 Stage Clearance
                  </option>
                  <option value="Asbestos Analyst - Asbestos Air Sampling">
                    Asbestos Analyst - Asbestos Air Sampling
                  </option>
                  <option value="Asbestos Analyst - Bulk Sampling">
                    Asbestos Analyst - Bulk Sampling
                  </option>
                  <option value="Asbestos Inspector/ Surveyor">
                    Asbestos Inspector/ Surveyor
                  </option>
                  <option value="Asbestos Removal Operative">
                    Asbestos Removal Operative
                  </option>
                  <option value="Asbestos Removal Supervisor">
                    Asbestos Removal Supervisor
                  </option>
                  <option value="Autoclaved Aerated Concrete Frame Erector">
                    Autoclaved Aerated Concrete Frame Erector
                  </option>
                  <option value="Automated People Mover Installer">
                    Automated People Mover Installer
                  </option>
                  <option value="Automatic Irrigation System Installer">
                    Automatic Irrigation System Installer
                  </option>
                  <option value="Automatic Monitoring & Surveying Installer">
                    Automatic Monitoring & Surveying Installer
                  </option>
                  <option value="Automatic Welding Process Operative">
                    Automatic Welding Process Operative
                  </option>
                  <option value="Axle Weighbridge Installer">
                    Axle Weighbridge Installer
                  </option>
                  <option value="Bench Joiner">Bench Joiner</option>
                  <option value="Blast Cleaning Contractor">
                    Blast Cleaning Contractor
                  </option>
                  <option value="Blind and Shutter Installer">
                    Blind and Shutter Installer
                  </option>
                  <option value="Bricklayer">Bricklayer</option>
                  <option value="Bricklayer">Bricklayer</option>
                  <option value="Brise Soleil/Louvre Installer">
                    Brise Soleil/Louvre Installer
                  </option>
                  <option value="Building Control Officer">
                    Building Control Officer
                  </option>
                  <option value="Building Maintenance & Estates Manager">
                    Building Maintenance & Estates Manager
                  </option>
                  <option value="Building Maintenance & Estates Supervisor">
                    Building Maintenance & Estates Supervisor
                  </option>
                  <option value="Building Management Control Systems Engineer">
                    Building Management Control Systems Engineer
                  </option>
                  <option value="Building Site Manager (Conservation)">
                    Building Site Manager (Conservation)
                  </option>
                  <option value="Building Surveying Assistant">
                    Building Surveying Assistant
                  </option>
                  <option value="Building Surveying Assistant">
                    Building Surveying Assistant
                  </option>
                  <option value="Built Up Felt Roofer">
                    Built Up Felt Roofer
                  </option>
                  <option value="Butt Fusion Operative">
                    Butt Fusion Operative
                  </option>
                  <option value="Cabinet Maker & Polisher">
                    Cabinet Maker & Polisher
                  </option>
                  <option
                    value="Capacitor Equipments Installer & Maintenance Operative"
                  >
                    Capacitor Equipments Installer & Maintenance Operative
                  </option>
                  <option value="Capital Equipment Installer">
                    Capital Equipment Installer
                  </option>
                  <option value="Car Park Deck Waterproofing Operative">
                    Car Park Deck Waterproofing Operative
                  </option>
                  <option value="Carpenter and Joiner">
                    Carpenter and Joiner
                  </option>
                  <option value="Cathodic Protection Engineer">
                    Cathodic Protection Engineer
                  </option>
                  <option value="Cathodic Protection Snr Technician">
                    Cathodic Protection Snr Technician
                  </option>
                  <option value="Cathodic Protection Technician">
                    Cathodic Protection Technician
                  </option>
                  <option value="Cavity Wall Tie Replacement Operative">
                    Cavity Wall Tie Replacement Operative
                  </option>
                  <option
                    value="CCTV Surveys of Drainage/Pipeline Systems Operative"
                  >
                    CCTV Surveys of Drainage/Pipeline Systems Operative
                  </option>
                  <option value="Chainsaw Maintenance and Cross Cutting only">
                    Chainsaw Maintenance and Cross Cutting only
                  </option>
                  <option value="Chemical Cleaning Specialist">
                    Chemical Cleaning Specialist
                  </option>
                  <option value="Chemical Grouting Operative">
                    Chemical Grouting Operative
                  </option>
                  <option value="Chimney Engineer">Chimney Engineer</option>
                  <option
                    value="Chlorination & Disinfection Equipment Installer"
                  >
                    Chlorination & Disinfection Equipment Installer
                  </option>
                  <option value="Civil & Structural Engineering Designer">
                    Civil & Structural Engineering Designer
                  </option>
                  <option value="Civil Engineer">Civil Engineer</option>
                  <option value="Civil Engineering Technician">
                    Civil Engineering Technician
                  </option>
                  <option value="Cleaning/ Flushing & Chlorination Operative">
                    Cleaning/ Flushing & Chlorination Operative
                  </option>
                  <option value="Clerk of Works">Clerk of Works</option>
                  <option value="Cold Form Steel Frame Erector">
                    Cold Form Steel Frame Erector
                  </option>
                  <option
                    value="Commissioning / Servicing &
                             Installation of Water System Engineer"
                  >
                    Commissioning / Servicing & Installation of Water System
                    Engineer
                  </option>
                  <option value="Commissioning Assistant">
                    Commissioning Assistant
                  </option>
                  <option value="Communications Technology Manager">
                    Communications Technology Manager
                  </option>
                  <option value="Communications Technology Supervisor">
                    Communications Technology Supervisor
                  </option>
                  <option value="Compressed Air Engineer & Pipe fitter">
                    Compressed Air Engineer & Pipe fitter
                  </option>
                  <option value="Conservation Consultant">
                    Conservation Consultant
                  </option>
                  <option value="Conservation Controller">
                    Conservation Controller
                  </option>
                  <option value="Conservation Technician">
                    Conservation Technician
                  </option>
                  <option value="Conservator">Conservator</option>
                  <option value="Conservator">Conservator</option>
                  <option value="Construction Built Environment Planner">
                    Construction Built Environment Planner
                  </option>
                  <option value="Construction Contracting Buyer">
                    Construction Contracting Buyer
                  </option>
                  <option value="Construction Contracting Buying Assistant">
                    Construction Contracting Buying Assistant
                  </option>
                  <option value="Construction Contracting Buying Assistant">
                    Construction Contracting Buying Assistant
                  </option>
                  <option value="Construction Contracting Estimating Assistant">
                    Construction Contracting Estimating Assistant
                  </option>
                  <option value="Construction Contracting Estimating Assistant">
                    Construction Contracting Estimating Assistant
                  </option>
                  <option value="Construction Contracting Estimator">
                    Construction Contracting Estimator
                  </option>
                  <option value="Construction Contracting Planning Assistant">
                    Construction Contracting Planning Assistant
                  </option>
                  <option value="Construction Contracting Planning Assistant">
                    Construction Contracting Planning Assistant
                  </option>
                  <option value="Construction Contracting Surveying Assistant">
                    Construction Contracting Surveying Assistant
                  </option>
                  <option value="Construction Contracting Surveying Assistant">
                    Construction Contracting Surveying Assistant
                  </option>
                  <option value="Construction Health and Safety Manager">
                    Construction Health and Safety Manager
                  </option>
                  <option value="Construction Health and Safety Officer">
                    Construction Health and Safety Officer
                  </option>
                  <option value="Construction Health and Safety Senior Manager">
                    Construction Health and Safety Senior Manager
                  </option>
                  <option value="Construction Materials Test Technician">
                    Construction Materials Test Technician
                  </option>
                  <option value="Construction Operative Spray Concretor">
                    Construction Operative Spray Concretor
                  </option>
                  <option value="Construction Operative Structural Concretor">
                    Construction Operative Structural Concretor
                  </option>
                  <option value="Construction Project Manager">
                    Construction Project Manager
                  </option>
                  <option value="Construction Site Manager (Conservation)">
                    Construction Site Manager (Conservation)
                  </option>
                  <option value="Construction Site Manager - Residential">
                    Construction Site Manager - Residential
                  </option>
                  <option value="Construction Site Manager - Sustainability">
                    Construction Site Manager - Sustainability
                  </option>
                  <option value="Construction Site Supervisor (Contracting)">
                    Construction Site Supervisor (Contracting)
                  </option>
                  <option
                    value="Construction Site Supervisor (Highways Maintenance)"
                  >
                    Construction Site Supervisor (Highways Maintenance)
                  </option>
                  <option
                    value="Construction Site Supervisor (Highways Maintenance)"
                  >
                    Construction Site Supervisor (Highways Maintenance)
                  </option>
                  <option value="Construction Site Supervisor - (Conservation)">
                    Construction Site Supervisor - (Conservation)
                  </option>
                  <option value="Construction Site Supervisor - (Conservation)">
                    Construction Site Supervisor - (Conservation)
                  </option>
                  <option value="Construction Site Supervisor - (Tunnelling)">
                    Construction Site Supervisor - (Tunnelling)
                  </option>
                  <option value="Construction Site Supervisor - Residential">
                    Construction Site Supervisor - Residential
                  </option>
                  <option value="Construction Site Supervisor - Residential">
                    Construction Site Supervisor - Residential
                  </option>
                  <option value="Control Panel Installer">
                    Control Panel Installer
                  </option>
                  <option value="Control Panel Manufacturer">
                    Control Panel Manufacturer
                  </option>
                  <option value="Conveyor Belt Operative">
                    Conveyor Belt Operative
                  </option>
                  <option value="Coving Installer">Coving Installer</option>
                  <option value="Craft Mason">Craft Mason</option>
                  <option value="Damp Proofer/ Wood Preserver">
                    Damp Proofer/ Wood Preserver
                  </option>
                  <option value="DC Power Equipment Installer">
                    DC Power Equipment Installer
                  </option>
                  <option value="Decorative Artist">Decorative Artist</option>
                  <option value="Decorative Artist">Decorative Artist</option>
                  <option value="Demolition Supervisor">
                    Demolition Supervisor
                  </option>
                  <option
                    value="Dental Equipment Service & Installation Engineer"
                  >
                    Dental Equipment Service & Installation Engineer
                  </option>
                  <option value="Directional Driller">
                    Directional Driller
                  </option>
                  <option value="Dishwasher Conveyor System Installer">
                    Dishwasher Conveyor System Installer
                  </option>
                  <option value="Disinfection System Installer">
                    Disinfection System Installer
                  </option>
                  <option value="Display Cabinet Installer">
                    Display Cabinet Installer
                  </option>
                  <option value="Domestic Appliance Installer">
                    Domestic Appliance Installer
                  </option>
                  <option value="Door Systems Engineer - Installation">
                    Door Systems Engineer - Installation
                  </option>
                  <option value="Door Systems Engineer - Installation & Repair">
                    Door Systems Engineer - Installation & Repair
                  </option>
                  <option value="Door Systems Engineer - Repair">
                    Door Systems Engineer - Repair
                  </option>
                  <option value="Drainage Maintenance & Surveying Operative">
                    Drainage Maintenance & Surveying Operative
                  </option>
                  <option value="Dry Liner: Finisher">
                    Dry Liner: Finisher
                  </option>
                  <option value="Dry Liner: Fixer">Dry Liner: Fixer</option>
                  <option value="Dust Extraction Systems Installer">
                    Dust Extraction Systems Installer
                  </option>
                  <option value="Eaves & Rainwater System Installer">
                    Eaves & Rainwater System Installer
                  </option>
                  <option value="Electrical & Electronic Security Operative">
                    Electrical & Electronic Security Operative
                  </option>
                  <option value="Electrical Co-ordination Draughtsman">
                    Electrical Co-ordination Draughtsman
                  </option>
                  <option value="Electrical Control Engineer">
                    Electrical Control Engineer
                  </option>
                  <option value="Electrical Control Panel Installer">
                    Electrical Control Panel Installer
                  </option>
                  <option value="Electrical Distribution Switchgear Installer">
                    Electrical Distribution Switchgear Installer
                  </option>
                  <option value="Electrical Generator Installer">
                    Electrical Generator Installer
                  </option>
                  <option
                    value="Electrical Installation & Distribution Operative"
                  >
                    Electrical Installation & Distribution Operative
                  </option>
                  <option value="Electrical LV Equipment Installer">
                    Electrical LV Equipment Installer
                  </option>
                  <option value="Electrical Solutions Installer">
                    Electrical Solutions Installer
                  </option>
                  <option value="Electrical Switchgear Installer">
                    Electrical Switchgear Installer
                  </option>
                  <option
                    value="Electrically Powered Mechanical Plant Installer"
                  >
                    Electrically Powered Mechanical Plant Installer
                  </option>
                  <option value="Electrostatic Paint Sprayer">
                    Electrostatic Paint Sprayer
                  </option>
                  <option value="Emergency Power Systems Installer">
                    Emergency Power Systems Installer
                  </option>
                  <option value="Energy Saving Systems Installer/Electrician">
                    Energy Saving Systems Installer/Electrician
                  </option>
                  <option value="Engineering Surveyor">
                    Engineering Surveyor
                  </option>
                  <option value="Engineering/Electrical Contractor">
                    Engineering/Electrical Contractor
                  </option>
                  <option value="Entrance Matting & Matwell Framing Installer">
                    Entrance Matting & Matwell Framing Installer
                  </option>
                  <option
                    value="Environmental Conservator (Rivers, Coasts and Waterways)"
                  >
                    Environmental Conservator (Rivers, Coasts and Waterways)
                  </option>
                  <option value="Environmental Manager (Construction)">
                    Environmental Manager (Construction)
                  </option>
                  <option value="Escalator Installer">
                    Escalator Installer
                  </option>
                  <option value="External Wall Insulation Installer">
                    External Wall Insulation Installer
                  </option>
                  <option value="Fabric and Membrane Cladding Installer">
                    Fabric and Membrane Cladding Installer
                  </option>
                  <option value="Facade Maintenance: Cleaner">
                    Facade Maintenance: Cleaner
                  </option>
                  <option value="Facade Maintenance: Surface Repairer">
                    Facade Maintenance: Surface Repairer
                  </option>
                  <option value="Facade Technician">Facade Technician</option>
                  <option value="Facilities Management Manager">
                    Facilities Management Manager
                  </option>
                  <option value="Facilities Management Operative">
                    Facilities Management Operative
                  </option>
                  <option value="Facilities Management Supervisor">
                    Facilities Management Supervisor
                  </option>
                  <option value="Fascia, Soffit and Bargeboard Installer">
                    Fascia, Soffit and Bargeboard Installer
                  </option>
                  <option value="Fence Installer - Vehicle Safety">
                    Fence Installer - Vehicle Safety
                  </option>
                  <option value="Fenestration Supervisor">
                    Fenestration Supervisor
                  </option>
                  <option value="Fenestration Surveyor">
                    Fenestration Surveyor
                  </option>
                  <option value="Fire Curtain Installer">
                    Fire Curtain Installer
                  </option>
                  <option value="Fire Extinguisher Engineer">
                    Fire Extinguisher Engineer
                  </option>
                  <option value="Fire Resistant Glazier">
                    Fire Resistant Glazier
                  </option>
                  <option value="Fire/Smoke Ventilation Installer">
                    Fire/Smoke Ventilation Installer
                  </option>
                  <option value="Flood Lighting System Installer">
                    Flood Lighting System Installer
                  </option>
                  <option value="Floorcoverer: Resilient/ Impervious">
                    Floorcoverer: Resilient/ Impervious
                  </option>
                  <option value="Floorcoverer: Textiles">
                    Floorcoverer: Textiles
                  </option>
                  <option value="Floorcoverer: Timber">
                    Floorcoverer: Timber
                  </option>
                  <option value="Flue Fitter">Flue Fitter</option>
                  <option value="Formworker">Formworker</option>
                  <option value="French Polisher">French Polisher</option>
                  <option value="Fridge & Freezer Installer">
                    Fridge & Freezer Installer
                  </option>
                  <option value="Fuel Tank, Pipe work, Fuel Pump Installer">
                    Fuel Tank, Pipe work, Fuel Pump Installer
                  </option>
                  <option value="Fume Cupboard Installer">
                    Fume Cupboard Installer
                  </option>
                  <option value="Furniture Installer">
                    Furniture Installer
                  </option>
                  <option value="Garage Equipment Installer">
                    Garage Equipment Installer
                  </option>
                  <option value="Gas Catering Equipment Installer">
                    Gas Catering Equipment Installer
                  </option>
                  <option value="Gas Fitters, Appliance Installer">
                    Gas Fitters, Appliance Installer
                  </option>
                  <option value="Gas Mains & Service Layer">
                    Gas Mains & Service Layer
                  </option>
                  <option value="Gas Membrane Installer">
                    Gas Membrane Installer
                  </option>
                  <option value="Gas Pipelines Installer (Medical)">
                    Gas Pipelines Installer (Medical)
                  </option>
                  <option value="Gas Vent & Chimney Systems Installer">
                    Gas Vent & Chimney Systems Installer
                  </option>
                  <option value="General Highways Maintenance Operative">
                    General Highways Maintenance Operative
                  </option>
                  <option value="Ground Anchor Installer">
                    Ground Anchor Installer
                  </option>
                  <option value="Ground Anchor Installer">
                    Ground Anchor Installer
                  </option>
                  <option value="Handrail/Balustrade/Staircase Installer">
                    Handrail/Balustrade/Staircase Installer
                  </option>
                  <option value="Haulier">Haulier</option>
                  <option value="Health Physic Surveyor">
                    Health Physic Surveyor
                  </option>
                  <option value="Heating & Frost Prevention Installer">
                    Heating & Frost Prevention Installer
                  </option>
                  <option value="Heating & Frost Prevention Installer">
                    Heating & Frost Prevention Installer
                  </option>
                  <option value="Heavy Jacking System Operative">
                    Heavy Jacking System Operative
                  </option>
                  <option value="Heavy Timber Frame Carpenter">
                    Heavy Timber Frame Carpenter
                  </option>
                  <option value="Heritage Skills - Blacksmith">
                    Heritage Skills - Blacksmith
                  </option>
                  <option value="Heritage Skills - Bricklayer">
                    Heritage Skills - Bricklayer
                  </option>
                  <option value="Heritage Skills - Carpenter and Joiner">
                    Heritage Skills - Carpenter and Joiner
                  </option>
                  <option value="Heritage Skills - Craft Mason">
                    Heritage Skills - Craft Mason
                  </option>
                  <option value="Heritage Skills - Earth Waller">
                    Heritage Skills - Earth Waller
                  </option>
                  <option value="Heritage Skills - Fibrous Plasterer">
                    Heritage Skills - Fibrous Plasterer
                  </option>
                  <option value="Heritage Skills - Finisher">
                    Heritage Skills - Finisher
                  </option>
                  <option value="Heritage Skills - Hard Metal Roofer">
                    Heritage Skills - Hard Metal Roofer
                  </option>
                  <option value="Heritage Skills - Memorial Mason">
                    Heritage Skills - Memorial Mason
                  </option>
                  <option value="Heritage Skills - Painter and Decorator">
                    Heritage Skills - Painter and Decorator
                  </option>
                  <option value="Heritage Skills - Roof Slater and Tiler">
                    Heritage Skills - Roof Slater and Tiler
                  </option>
                  <option value="Heritage Skills - Solid Plasterer">
                    Heritage Skills - Solid Plasterer
                  </option>
                  <option value="Heritage Skills - Specialist Leadworker">
                    Heritage Skills - Specialist Leadworker
                  </option>
                  <option value="Heritage Skills - Stone Carver">
                    Heritage Skills - Stone Carver
                  </option>
                  <option value="Heritage Skills - Stone Mason Banker">
                    Heritage Skills - Stone Mason Banker
                  </option>
                  <option value="Heritage Skills - Stone Mason Banker Fixer">
                    Heritage Skills - Stone Mason Banker Fixer
                  </option>
                  <option value="Heritage Skills - Stone Mason Fixer">
                    Heritage Skills - Stone Mason Fixer
                  </option>
                  <option
                    value="Heritage Skills - Stone Mason Marble and Granite"
                  >
                    Heritage Skills - Stone Mason Marble and Granite
                  </option>
                  <option value="Heritage Skills - Stone Mason Setter Out">
                    Heritage Skills - Stone Mason Setter Out
                  </option>
                  <option value="Heritage Skills - Wall and Floor Tiler">
                    Heritage Skills - Wall and Floor Tiler
                  </option>
                  <option value="Highway Amenity & Car Park Lighting Installer">
                    Highway Amenity & Car Park Lighting Installer
                  </option>
                  <option value="Hire Desk Operative">
                    Hire Desk Operative
                  </option>
                  <option value="Hire Desk Supervisor">
                    Hire Desk Supervisor
                  </option>
                  <option value="Hoist Installer">Hoist Installer</option>
                  <option
                    value="Hospital/Nursing Home Bathing Equipment Installer"
                  >
                    Hospital/Nursing Home Bathing Equipment Installer
                  </option>
                  <option value="HVCA Controls Engineer">
                    HVCA Controls Engineer
                  </option>
                  <option
                    value="HVCA Controls Panel Design Manufacturer,
                             Service & Commission Engineer"
                  >
                    HVCA Controls Panel Design Manufacturer, Service &
                    Commission Engineer
                  </option>
                  <option value="Hydraseeding, Weed Control Operative">
                    Hydraseeding, Weed Control Operative
                  </option>
                  <option value="Hydraulic Bridge Systems Installer">
                    Hydraulic Bridge Systems Installer
                  </option>
                  <option value="Hydraulic Pump Installer">
                    Hydraulic Pump Installer
                  </option>
                  <option value="ICQ Operative - Pharmaceutical Equipment">
                    ICQ Operative - Pharmaceutical Equipment
                  </option>
                  <option value="In Situ Flooring: Cementitous Screeding">
                    In Situ Flooring: Cementitous Screeding
                  </option>
                  <option value="In Situ Flooring: Concrete">
                    In Situ Flooring: Concrete
                  </option>
                  <option value="In Situ Flooring: Resin Coating">
                    In Situ Flooring: Resin Coating
                  </option>
                  <option value="In Situ Flooring: Resin Screed">
                    In Situ Flooring: Resin Screed
                  </option>
                  <option value="In Situ Flooring: Resin Self Smoothing">
                    In Situ Flooring: Resin Self Smoothing
                  </option>
                  <option value="Incident Support Unit Operative">
                    Incident Support Unit Operative
                  </option>
                  <option
                    value="Industrial & Domestic Drain &
                             Pipe Cleaning/Repair Service/Drain Relining Operative"
                  >
                    Industrial & Domestic Drain & Pipe Cleaning/Repair
                    Service/Drain Relining Operative
                  </option>
                  <option value="Industrial Boiler Erector">
                    Industrial Boiler Erector
                  </option>
                  <option value="Industrial Coatings Applicator">
                    Industrial Coatings Applicator
                  </option>
                  <option value="Industrial Coatings Applicator">
                    Industrial Coatings Applicator
                  </option>
                  <option value="Industrial Control Systems Engineer">
                    Industrial Control Systems Engineer
                  </option>
                  <option value="Industrial Gas Commissioning Engineers">
                    Industrial Gas Commissioning Engineers
                  </option>
                  <option value="Industrial Storage Equipment Installer">
                    Industrial Storage Equipment Installer
                  </option>
                  <option value="Installation Co-ordinator">
                    Installation Co-ordinator
                  </option>
                  <option value="Installation Engineer: Mobile Conductors">
                    Installation Engineer: Mobile Conductors
                  </option>
                  <option value="Installer of Storage Equipment">
                    Installer of Storage Equipment
                  </option>
                  <option value="Installer: Conservatories">
                    Installer: Conservatories
                  </option>
                  <option value="Installer: Curtain Waller">
                    Installer: Curtain Waller
                  </option>
                  <option value="Installer: Windows and Doors">
                    Installer: Windows and Doors
                  </option>
                  <option
                    value="Installing & Commissioning Humidifiers Operative"
                  >
                    Installing & Commissioning Humidifiers Operative
                  </option>
                  <option value="Instrument Technician">
                    Instrument Technician
                  </option>
                  <option
                    value="Insulated Panel Installer - (Insulated Enclosures - Commercial)"
                  >
                    Insulated Panel Installer - (Insulated Enclosures -
                    Commercial)
                  </option>
                  <option
                    value="Insulated Panel Installer - (Insulated Enclosures - Industrial)"
                  >
                    Insulated Panel Installer - (Insulated Enclosures -
                    Industrial)
                  </option>
                  <option value="Internal Insulation Operative">
                    Internal Insulation Operative
                  </option>
                  <option value="Invasive Weed Surveyor">
                    Invasive Weed Surveyor
                  </option>
                  <option value="Laboratory Technician">
                    Laboratory Technician
                  </option>
                  <option value="Land Drilling - Driller">
                    Land Drilling - Driller
                  </option>
                  <option value="Land Drilling - Lead Driller">
                    Land Drilling - Lead Driller
                  </option>
                  <option value="Land Surveyor">Land Surveyor</option>
                  <option value="Landscape Architect">
                    Landscape Architect
                  </option>
                  <option value="Landscape Manager">Landscape Manager</option>
                  <option value="Landscape Supervisor">
                    Landscape Supervisor
                  </option>
                  <option
                    value="Large Electrical Power Distribution
                             Switchboard Manufacturer/Installer"
                  >
                    Large Electrical Power Distribution Switchboard
                    Manufacturer/Installer
                  </option>
                  <option value="Laundry Equipment Installer">
                    Laundry Equipment Installer
                  </option>
                  <option value="Lift & Escalator Manager">
                    Lift & Escalator Manager
                  </option>
                  <option value="Lift & Escalator Supervisor">
                    Lift & Escalator Supervisor
                  </option>
                  <option value="Lift/Escalator Tester">
                    Lift/Escalator Tester
                  </option>
                  <option value="Lifting Equipment & Gear Installer">
                    Lifting Equipment & Gear Installer
                  </option>
                  <option value="Light Control System Installer">
                    Light Control System Installer
                  </option>
                  <option value="Light Steel Frame Erector">
                    Light Steel Frame Erector
                  </option>
                  <option value="Lightning Conductor Engineer">
                    Lightning Conductor Engineer
                  </option>
                  <option
                    value="Lightning Protective Systems Inspector and Tester"
                  >
                    Lightning Protective Systems Inspector and Tester
                  </option>
                  <option value="Liquid Waterproofing Operative">
                    Liquid Waterproofing Operative
                  </option>
                  <option value="Loading Bay & Elevating Equipment Engineer">
                    Loading Bay & Elevating Equipment Engineer
                  </option>
                  <option value="Loading Bay Equipment Installer">
                    Loading Bay Equipment Installer
                  </option>
                  <option value="Loft Insulation Installer">
                    Loft Insulation Installer
                  </option>
                  <option value="Louvre & Smoke Ventilation Installer">
                    Louvre & Smoke Ventilation Installer
                  </option>
                  <option value="Louvre and Diffuser Cleaning Operative">
                    Louvre and Diffuser Cleaning Operative
                  </option>
                  <option value="Louvre Grills Installer">
                    Louvre Grills Installer
                  </option>
                  <option value="Machine Applied Plasterer">
                    Machine Applied Plasterer
                  </option>
                  <option value="Machine Tunnelling Operative">
                    Machine Tunnelling Operative
                  </option>
                  <option
                    value="Mains Rehabilitation & Multi Utility Operations Engineer"
                  >
                    Mains Rehabilitation & Multi Utility Operations Engineer
                  </option>
                  <option value="Maintenance Operative">
                    Maintenance Operative
                  </option>
                  <option value="Masonry Work Operative">
                    Masonry Work Operative
                  </option>
                  <option value="Mastic Asphalter">Mastic Asphalter</option>
                  <option value="Mechanical Fitter">Mechanical Fitter</option>
                  <option
                    value="Mechanical Fitter, Sheet Metal Worker & Welder"
                  >
                    Mechanical Fitter, Sheet Metal Worker & Welder
                  </option>
                  <option value="Medical & Industrial Gas Pipeline Installer">
                    Medical & Industrial Gas Pipeline Installer
                  </option>
                  <option value="Medical and Laboratory Gas System Installer">
                    Medical and Laboratory Gas System Installer
                  </option>
                  <option value="Medical Equipment Installer">
                    Medical Equipment Installer
                  </option>
                  <option value="Medical Gas Technician">
                    Medical Gas Technician
                  </option>
                  <option value="Modular Buildings Installer/ Dismantler">
                    Modular Buildings Installer/ Dismantler
                  </option>
                  <option value="Multi Fuel Cooker Installer">
                    Multi Fuel Cooker Installer
                  </option>
                  <option value="Non Hazardous Waste Removal Operative">
                    Non Hazardous Waste Removal Operative
                  </option>
                  <option value="Occupational Work Supervisor">
                    Occupational Work Supervisor
                  </option>
                  <option
                    value="On-Site Repair/Spray Composite Cladding Panels"
                  >
                    On-Site Repair/Spray Composite Cladding Panels
                  </option>
                  <option value="Operable Partitioner">
                    Operable Partitioner
                  </option>
                  <option value="Painter and Decorator Industrial">
                    Painter and Decorator Industrial
                  </option>
                  <option value="Panel Wireman">Panel Wireman</option>
                  <option value="Partition Fixer/ Demountable Partitioner">
                    Partition Fixer/ Demountable Partitioner
                  </option>
                  <option value="Passive Fire Protection">
                    Passive Fire Protection
                  </option>
                  <option
                    value="Personal Fall Protection Installer - Permanent Solutions"
                  >
                    Personal Fall Protection Installer - Permanent Solutions
                  </option>
                  <option value="Pesticides Foreman">Pesticides Foreman</option>
                  <option value="Pesticides Manager">Pesticides Manager</option>
                  <option value="Pipefreezing - Pressure Grouting Operative">
                    Pipefreezing - Pressure Grouting Operative
                  </option>
                  <option value="Pipefreezing and Isolation Service Operative">
                    Pipefreezing and Isolation Service Operative
                  </option>
                  <option
                    value="Pipeline Intervention/Hot Tapping Technician"
                  ></option>
                  <option value="Pipeline Renovator">Pipeline Renovator</option>
                  <option
                    value="Plant Mechanic/ Construction Plant Maintenance and Repair"
                  >
                    Plant Mechanic/ Construction Plant Maintenance and Repair
                  </option>
                  <option value="Plasterer: Fibrous">Plasterer: Fibrous</option>
                  <option value="Plasterer: Solid">Plasterer: Solid</option>
                  <option value="Platform Lift Installer">
                    Platform Lift Installer
                  </option>
                  <option value="Portable Building Installer/ Dismantler">
                    Portable Building Installer/ Dismantler
                  </option>
                  <option value="Post Tensioner Operative">
                    Post Tensioner Operative
                  </option>
                  <option value="Power System Installer">
                    Power System Installer
                  </option>
                  <option value="Precast Concrete Installer">
                    Precast Concrete Installer
                  </option>
                  <option value="Principal Utilities Mapping Surveyor">
                    Principal Utilities Mapping Surveyor
                  </option>
                  <option
                    value="Process & Control Eng Water Treatment Operative"
                  >
                    Process & Control Eng Water Treatment Operative
                  </option>
                  <option value="Process Commissioning Engineer">
                    Process Commissioning Engineer
                  </option>
                  <option
                    value="Process, Design Engineer of Water/Waste Water Projects"
                  >
                    Process, Design Engineer of Water/Waste Water Projects
                  </option>
                  <option value="Property and Caretaking Supervisor">
                    Property and Caretaking Supervisor
                  </option>
                  <option value="Public Address System Installer">
                    Public Address System Installer
                  </option>
                  <option value="Public Utilities Electricity Cable Layer">
                    Public Utilities Electricity Cable Layer
                  </option>
                  <option value="Quality Manager">Quality Manager</option>
                  <option value="Quantity Surveying Assistant">
                    Quantity Surveying Assistant
                  </option>
                  <option value="Quantity Surveyor">Quantity Surveyor</option>
                  <option value="Radiant Panel Installer">
                    Radiant Panel Installer
                  </option>
                  <option value="Railway Engineer - Track Maintenance">
                    Railway Engineer - Track Maintenance
                  </option>
                  <option value="Rain Water Goods Installer">
                    Rain Water Goods Installer
                  </option>
                  <option value="Remedial Treatment Surveyor">
                    Remedial Treatment Surveyor
                  </option>
                  <option value="Remediation Technician">
                    Remediation Technician
                  </option>
                  <option
                    value="Repair Technician
                             (External/Internal Scrapes, Dents & Chips to buildings)"
                  >
                    Repair Technician (External/Internal Scrapes, Dents & Chips
                    to buildings)
                  </option>
                  <option value="Resin & Grout Injection Operative">
                    Resin & Grout Injection Operative
                  </option>
                  <option value="Road Builder - Soil Stabilisation">
                    Road Builder - Soil Stabilisation
                  </option>
                  <option value="Road Building: Bituminous Pavior">
                    Road Building: Bituminous Pavior
                  </option>
                  <option value="Road Building: Concrete Pavior">
                    Road Building: Concrete Pavior
                  </option>
                  <option value="Road Building: Micro Asphalter">
                    Road Building: Micro Asphalter
                  </option>
                  <option value="Road Building: Pavement Marking Hand">
                    Road Building: Pavement Marking Hand
                  </option>
                  <option value="Road Building: Pavement Marking Machine">
                    Road Building: Pavement Marking Machine
                  </option>
                  <option value="Road Building: Pavement Marking Stud">
                    Road Building: Pavement Marking Stud
                  </option>
                  <option value="Road Building: Surface Dresser">
                    Road Building: Surface Dresser
                  </option>
                  <option value="Road Pavement Consulting Engineer">
                    Road Pavement Consulting Engineer
                  </option>
                  <option value="Roof Line System Installer">
                    Roof Line System Installer
                  </option>
                  <option value="Roof Sheeter and Cladder">
                    Roof Sheeter and Cladder
                  </option>
                  <option value="Roof Slater and Tiler">
                    Roof Slater and Tiler
                  </option>
                  <option value="Roof Slater and Tiler - New Roof">
                    Roof Slater and Tiler - New Roof
                  </option>
                  <option value="Roof Slater and Tiler - Re Roof">
                    Roof Slater and Tiler - Re Roof
                  </option>
                  <option
                    value="Rubber/PVC Linings Water Storage Tanks Installer"
                  >
                    Rubber/PVC Linings Water Storage Tanks Installer
                  </option>
                  <option value="Safety Net Rigger">Safety Net Rigger</option>
                  <option value="Sanitary Ware Repairer">
                    Sanitary Ware Repairer
                  </option>
                  <option value="Sauna/Steam Room & Spa Installer">
                    Sauna/Steam Room & Spa Installer
                  </option>
                  <option value="Sealant Application Supervisor">
                    Sealant Application Supervisor
                  </option>
                  <option value="Sealant Applicator">Sealant Applicator</option>
                  <option value="Security System Installer">
                    Security System Installer
                  </option>
                  <option value="Senior Transportation Practitioner">
                    Senior Transportation Practitioner
                  </option>
                  <option value="Senior Utilities Mapping Surveyor">
                    Senior Utilities Mapping Surveyor
                  </option>
                  <option value="Separation Plant Operative">
                    Separation Plant Operative
                  </option>
                  <option value="Shopfitter Benchwork">
                    Shopfitter Benchwork
                  </option>
                  <option value="Shotfiring Operator">
                    Shotfiring Operator
                  </option>
                  <option value="Shower/Steam Cubicles & Whirlpool Installer">
                    Shower/Steam Cubicles & Whirlpool Installer
                  </option>
                  <option value="Sign Installer - Illuminated">
                    Sign Installer - Illuminated
                  </option>
                  <option value="Sign Installer - Non Illuminated">
                    Sign Installer - Non Illuminated
                  </option>
                  <option value="Sign Maintenance/Repair Operative">
                    Sign Maintenance/Repair Operative
                  </option>
                  <option value="Single Ply Roofer">Single Ply Roofer</option>
                  <option value="Site Logistics - General">
                    Site Logistics - General
                  </option>
                  <option value="Site Technical Assistant">
                    Site Technical Assistant
                  </option>
                  <option value="Smoke & Natural Ventilation Installer">
                    Smoke & Natural Ventilation Installer
                  </option>
                  <option
                    value="Soil & Groundwater Remedition Systems Installer"
                  >
                    Soil & Groundwater Remedition Systems Installer
                  </option>
                  <option value="Soil Aeration and Decompaction Technician">
                    Soil Aeration and Decompaction Technician
                  </option>
                  <option value="Spatial Data Manager">
                    Spatial Data Manager
                  </option>
                  <option
                    value="Specialist Access Engineering & Maint. Installer"
                  >
                    Specialist Access Engineering & Maint. Installer
                  </option>
                  <option value="Specialist Cladding and Rainscreen Operative">
                    Specialist Cladding and Rainscreen Operative
                  </option>
                  <option value="Specialist Operative - Sprayed Concrete">
                    Specialist Operative - Sprayed Concrete
                  </option>
                  <option value="Specialist Operative: Concrete Placer">
                    Specialist Operative: Concrete Placer
                  </option>
                  <option value="Specialist Operative: General">
                    Specialist Operative: General
                  </option>
                  <option value="Specialist Operative: Land Drilling">
                    Specialist Operative: Land Drilling
                  </option>
                  <option value="Specialist Operative: Pavior">
                    Specialist Operative: Pavior
                  </option>
                  <option value="Specialist Operative: Piling">
                    Specialist Operative: Piling
                  </option>
                  <option value="Specialist Operative: Spray Concretor">
                    Specialist Operative: Spray Concretor
                  </option>
                  <option value="Specialist Operative: Tunnelling">
                    Specialist Operative: Tunnelling
                  </option>
                  <option value="Specialist Rendering Operative">
                    Specialist Rendering Operative
                  </option>
                  <option value="Specialist Restorer: Historic Sturctures">
                    Specialist Restorer: Historic Sturctures
                  </option>
                  <option value="Specialist Restorer: Historic Sturctures">
                    Specialist Restorer: Historic Sturctures
                  </option>
                  <option value="Specialist Vacuum Sewage System Installer">
                    Specialist Vacuum Sewage System Installer
                  </option>
                  <option value="Specialist Water Treatment Operative">
                    Specialist Water Treatment Operative
                  </option>
                  <option value="Specialist Water/Sewer Rehab Operative">
                    Specialist Water/Sewer Rehab Operative
                  </option>
                  <option value="Spoil Removal Equipment Operative">
                    Spoil Removal Equipment Operative
                  </option>
                  <option value="Sprayed Concrete Lining Tunnelling Operative">
                    Sprayed Concrete Lining Tunnelling Operative
                  </option>
                  <option value="Stair Tower Operative">
                    Stair Tower Operative
                  </option>
                  <option value="Stairlift Engineer">Stairlift Engineer</option>
                  <option value="Steeplejack">Steeplejack</option>
                  <option value="Sticker Operative (Plaster Board/Ceiling)">
                    Sticker Operative (Plaster Board/Ceiling)
                  </option>
                  <option value="Stone Cladding Installer">
                    Stone Cladding Installer
                  </option>
                  <option value="Stone Fixer">Stone Fixer</option>
                  <option value="Stonemason: Banker">Stonemason: Banker</option>
                  <option value="Stonemason: Banker">Stonemason: Banker</option>
                  <option value="Stonemason: Banker/ Fixer">
                    Stonemason: Banker/ Fixer
                  </option>
                  <option value="Stonemason: Banker/ Fixer">
                    Stonemason: Banker/ Fixer
                  </option>
                  <option value="Stonemason: Fixer">Stonemason: Fixer</option>
                  <option value="Stonemason: Fixer">Stonemason: Fixer</option>
                  <option value="Stonemason: Marble and Granite">
                    Stonemason: Marble and Granite
                  </option>
                  <option value="Stonemason: Setter Out">
                    Stonemason: Setter Out
                  </option>
                  <option value="Street Lighting Installer">
                    Street Lighting Installer
                  </option>
                  <option value="Structural Post and Beam Carpenter">
                    Structural Post and Beam Carpenter
                  </option>
                  <option value="Structural Repairer - Reinforcement">
                    Structural Repairer - Reinforcement
                  </option>
                  <option value="Sub-Structure Operative - Preformed Piles">
                    Sub-Structure Operative - Preformed Piles
                  </option>
                  <option
                    value="Sub-Structure Operative - Retaining Structures"
                  >
                    Sub-Structure Operative - Retaining Structures
                  </option>
                  <option value="Surveying Assistant (General Practice)">
                    Surveying Assistant (General Practice)
                  </option>
                  <option value="Suspended Access Equipment Installer">
                    Suspended Access Equipment Installer
                  </option>
                  <option value="Swimming Pool Installer">
                    Swimming Pool Installer
                  </option>
                  <option value="Syphonic Drainage System Installer">
                    Syphonic Drainage System Installer
                  </option>
                  <option value="Tacker Operative (Plaster Board)">
                    Tacker Operative (Plaster Board)
                  </option>
                  <option value="Tank Cleaner">Tank Cleaner</option>
                  <option value="Technical Designer (Built Environment)">
                    Technical Designer (Built Environment)
                  </option>
                  <option value="Temporary Excavation Support Operative">
                    Temporary Excavation Support Operative
                  </option>
                  <option value="Temporary Industrial Rope Access Installer">
                    Temporary Industrial Rope Access Installer
                  </option>
                  <option value="Temporary VRS Installer">
                    Temporary VRS Installer
                  </option>
                  <option value="Temproary Traffic Management Operative">
                    Temproary Traffic Management Operative
                  </option>
                  <option value="Terrazzo, Marble, Granite Installer">
                    Terrazzo, Marble, Granite Installer
                  </option>
                  <option value="Timber Frame Erector">
                    Timber Frame Erector
                  </option>
                  <option value="Tinsmith">Tinsmith</option>
                  <option value="Town Planning Assistant">
                    Town Planning Assistant
                  </option>
                  <option value="Town Planning Practitioner">
                    Town Planning Practitioner
                  </option>
                  <option value="Trace Heating System Installer">
                    Trace Heating System Installer
                  </option>
                  <option value="Train Cleaning Equipment Installer">
                    Train Cleaning Equipment Installer
                  </option>
                  <option value="Transportation Practitioner">
                    Transportation Practitioner
                  </option>
                  <option value="Tunnel Transport Operative">
                    Tunnel Transport Operative
                  </option>
                  <option value="Tunneller">Tunneller</option>
                  <option value="Tunneller - Machine Miner">
                    Tunneller - Machine Miner
                  </option>
                  <option value="Tunneller - NATM Miner">
                    Tunneller - NATM Miner
                  </option>
                  <option
                    value="Tunneller - Pipejacking/Micro Tunnelling Miner"
                  >
                    Tunneller - Pipejacking/Micro Tunnelling Miner
                  </option>
                  <option value="Tunnelling Operative - Electrician's Mate">
                    Tunnelling Operative - Electrician's Mate
                  </option>
                  <option value="Tunnelling Services Operative">
                    Tunnelling Services Operative
                  </option>
                  <option value="Underground Mapping Operative">
                    Underground Mapping Operative
                  </option>
                  <option value="Underpinner">Underpinner</option>
                  <option value="Upholsterer">Upholsterer</option>
                  <option value="Utilities Mapping Surveyor">
                    Utilities Mapping Surveyor
                  </option>
                  <option value="Utilities Mapping Technician">
                    Utilities Mapping Technician
                  </option>
                  <option
                    value="Value Actuator Commissioning & Repair Operative"
                  >
                    Value Actuator Commissioning & Repair Operative
                  </option>
                  <option value="Velocity Patcher">Velocity Patcher</option>
                  <option value="Video Surveillance Operator/Installer">
                    Video Surveillance Operator/Installer
                  </option>
                  <option value="Wall and Floor Tiler">
                    Wall and Floor Tiler
                  </option>
                  <option value="Washroom Systems Installer">
                    Washroom Systems Installer
                  </option>
                  <option value="Waste Handling Installer">
                    Waste Handling Installer
                  </option>
                  <option value="Waste Management Operative">
                    Waste Management Operative
                  </option>
                  <option value="Waste/Water Treatment Operative">
                    Waste/Water Treatment Operative
                  </option>
                  <option value="Water & Sewage Industry Engineer">
                    Water & Sewage Industry Engineer
                  </option>
                  <option value="Water Cleaner/Descaler Installer">
                    Water Cleaner/Descaler Installer
                  </option>
                  <option value="Water Feature Installer">
                    Water Feature Installer
                  </option>
                  <option value="Water Filtration/Treatment Engineer">
                    Water Filtration/Treatment Engineer
                  </option>
                  <option value="Water Flow Control & Regulation Technician">
                    Water Flow Control & Regulation Technician
                  </option>
                  <option value="Water Hygiene & Maintenance Technician">
                    Water Hygiene & Maintenance Technician
                  </option>
                  <option value="Water Jetter - Cutting & Breaking">
                    Water Jetter - Cutting & Breaking
                  </option>
                  <option value="Water Jetter - Drains and Sewers">
                    Water Jetter - Drains and Sewers
                  </option>
                  <option value="Water Jetter - Surface Cleaning">
                    Water Jetter - Surface Cleaning
                  </option>
                  <option value="Water Jetter - Tube Cleaning">
                    Water Jetter - Tube Cleaning
                  </option>
                  <option value="Water Leak Detection Systems Installer">
                    Water Leak Detection Systems Installer
                  </option>
                  <option value="Water Leakage Testing Technician">
                    Water Leakage Testing Technician
                  </option>
                  <option value="Water Mains Layer">Water Mains Layer</option>
                  <option
                    value="Water Meter Installer & General Plumbing Engineer"
                  >
                    Water Meter Installer & General Plumbing Engineer
                  </option>
                  <option value="Water Park Equipment Installer">
                    Water Park Equipment Installer
                  </option>
                  <option value="Water Sculpture Installer">
                    Water Sculpture Installer
                  </option>
                  <option value="Water Services Layer">
                    Water Services Layer
                  </option>
                  <option value="Water Sewage Penstock Installer">
                    Water Sewage Penstock Installer
                  </option>
                  <option value="Water Storage Tank Erector">
                    Water Storage Tank Erector
                  </option>
                  <option
                    value="Water Systems, Safety Cabinet, Incubator Installer"
                  >
                    Water Systems, Safety Cabinet, Incubator Installer
                  </option>
                  <option value="Water Treatment Commissioning Engineer">
                    Water Treatment Commissioning Engineer
                  </option>
                  <option value="Water Treatment Equipment Installer">
                    Water Treatment Equipment Installer
                  </option>
                  <option
                    value="Water, Petro Chemical & Process Industry Installer"
                  >
                    Water, Petro Chemical & Process Industry Installer
                  </option>
                  <option value="Welding Examiner">Welding Examiner</option>
                  <option value="Window Opening/Closing Equipment Installer">
                    Window Opening/Closing Equipment Installer
                  </option>
                  <option value="Wood Machinist">Wood Machinist</option>
                  <option value="Wood Preserver/ Damp Proofer">
                    Wood Preserver/ Damp Proofer
                  </option>
                  <option value="Work Surface Installer">
                    Work Surface Installer
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal has-text-left">
            <label class="label">Course Type</label>
          </div>
          <div class="field-body">
            <div class="field">
              <b-field>
                <b-select
                  @input="updateCourseType"
                  :value="course.type"
                  @focus="() => setCourseId(course.id)"
                  id="course-type"
                  ref="course-type"
                  icon="desktop"
                  expanded
                >
                  <option value="Health and Safety Awareness course (online)">
                    Health &amp; Safety Awareness course (online)
                  </option>
                  <option value="Level 1 Award (online)">
                    Level 1 Health and Safety in a Construction Environment
                    (online)
                  </option>
                  <option value="SMSTS">SMSTS</option>
                  <option value="SSSTS">SSSTS</option>
                  <option value="NVQ Level 2">NVQ Level 2</option>
                  <option value="NVQ Level 3">NVQ Level 3</option>
                  <option value="NVQ Level 6">NVQ Level 6</option>
                </b-select>
              </b-field>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal has-text-left">
            <label class="label">Status</label>
          </div>
          <div class="field-body">
            <div class="field">
              <b-field>
                <b-select
                  @input="updateCourseStatus"
                  @focus="() => setCourseId(course.id)"
                  :value="course.status"
                  id="course-status"
                  ref="course-status"
                  icon="desktop"
                  expanded
                >
                  <option value="Open">Open</option>
                  <option value="Booked">Booked</option>
                  <option value="To Be Booked">To Be Booked</option>
                  <option value="To be booked">To be booked(old; do not set)</option>
                  <option value="Mailed Provider">Mailed Provider</option>
                  <option value="Passed">Passed</option>
                  <option value="Retake Due">Retake Due</option>
                  <option value="Dispute">Dispute</option>
                  <option value="Cancelled">Cancelled</option>
                </b-select>
              </b-field>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal has-text-left">
            <label class="label">Final Assessment</label>
          </div>
          <div class="field-body">
            <b-field>
              <b-datepicker
                placeholder="Click to select date..."
                id="final-assessment-date"
                ref="final-assessment-date"
                icon="calendar-today"
                @focus="() => setCourseId(course.id)"
                @input="updateFinalAssessmentDate"
                :value="new Date(course.date)"
                :date-formatter="
                  (date) => new Intl.DateTimeFormat('in-IN').format(date)
                "
                trap-focus
              >
              </b-datepicker>
            </b-field>
          </div>
        </div>

        <div v-if="course.comment" class="field is-horizontal">
          <div class="field-label is-normal has-text-left">
            <label class="label">Comment</label>
          </div>
          <div class="field-body">
            <div class="field">
              <b-field>
                <b-input
                  @input="updateComment"
                  @focus="() => setCourseId(course.id)"
                  id="comment"
                  ref="comment"
                  :value="course.comment"
                  type="textarea"
                ></b-input>
              </b-field>
            </div>
          </div>
        </div>

        <div class="field has-text-centered">
          <b-button
            expanded
            icon-right="arrow-circle-right"
            type="is-success"
            @click.prevent="courseSubmit"
          >
            Submit Course
          </b-button>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-full">
        <div class="field">
          <b-collapse :open="false" position="is-top" :aria-id="course.id">
            <template #trigger="props">
              <button class="button is-info is-light is-fullwidth">
                <span class="icon">
                  <i v-if="!props.open" class="fas fa-chevron-down"></i>
                  <i v-else class="fas fa-chevron-up"></i>
                </span>
                <span>
                  {{
                    !props.open
                      ? "View Comments and Activity"
                      : "Hide Comments and Activity"
                  }}
                </span>
              </button>
            </template>
            <div class="columns is-centered">
              <div class="column is-three-quarters">
                <Comments
                  :entity-id="course.id"
                  :comments="course.GenComments"
                  type="online-course"
                />
              </div>
            </div>
            <hr />
            <div class="columns is-centered">
              <div class="column is-three-quarters">
                <Activity :activities="course.Activities" />
              </div>
            </div>
          </b-collapse>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { getHumanDate } from '@/common';
import Comments from '@/components/Comments.vue';
import Activity from '@/components/Activity.vue';

export default {
  name: 'OnlineCourse',
  data: () => ({
    updatedCourseFields: {},
  }),
  props: ['course'],
  components: {
    Comments,
    Activity,
  },
  methods: {
    ...mapActions(['updateCourseDetails', 'setCourseId']),
    getHumanDate,
    createSnackbar(message, success) {
      return this.$buefy.toast.open({
        message,
        type: success ? 'is-success' : 'is-warning',
        container: 'section',
        position: 'is-top',
      });
    },
    updateCourseTrait(e) {
      this.updatedCourseFields.trait = e;
    },
    updateCourseType(e) {
      this.updatedCourseFields.type = e;
    },
    updateCourseStatus(e) {
      this.updatedCourseFields.status = e;
    },
    updateComment(e) {
      this.updatedCourseFields.comment = e;
    },
    updateFinalAssessmentDate(e) {
      this.updatedCourseFields.date = e;
    },
    async courseSubmit() {
      try {
        await this.updateCourseDetails(this.updatedCourseFields);
        return this.createSnackbar('Online Course Detail updated', true);
      } catch (error) {
        console.error(error);
        return this.createSnackbar('Error while updating details');
      } finally {
        window.scrollTo(0, 0);
      }
    },
  },
};
</script>
