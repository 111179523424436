<template>
    <div>
        <Order />
    </div>
</template>

<script>
import Order from '@/components/Forms/Order/Order.vue';
import { mapState } from 'vuex';

export default {
  components: {
    Order,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapState({
      user: ({ primary: { user } }) => user,
    }),
  },
};
</script>
