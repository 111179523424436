<template>
  <section class="section" id="online-course">
    <div v-if="courseCreated">
      <h1>Course created</h1>
    </div>
    <div v-if="!courseCreated">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-three-quarters">
            <h1 class="title">Course Details</h1>
          </div>
        </div>
        <div class="columns is-centered has-text-left">
          <div class="column is-three-quarters">

            <div class="field is-horizontal">
          <div class="field-label is-normal has-text-left">
            <label class="label">Location</label>
          </div>
          <div class="field-body">
              <b-field>
                <b-field>
                    <b-select
                      @input="updateLocation"
                      :value="getLocation"
                      id="location"
                      ref="location"
                      expanded
                    >
                      <option v-for="(location, i) in locations" :value="location" :key="i">
                        {{location.name}}
                      </option>
                    </b-select>
                  </b-field>
              </b-field>
            </div>
          </div>

        <div v-if="locationId" class="field is-horizontal">
          <div class="field-label is-normal has-text-left">
            <label class="label">Assessment Date</label>
          </div>
          <div class="field-body">
              <b-field>
                <b-datetimepicker
                  placeholder="Click to select date..."
                  id="datetime"
                  ref="datetime"
                  icon="calendar-today"
                  @input="updateDate"
                  :value="datetime"
                  :min-datetime="minDate"
                  :max-datetime="maxDate"
                  :date-formatter="
                    (date) => new Intl.DateTimeFormat('in-IN').format(date)
                  "
                  trap-focus
                >
                </b-datetimepicker>
              </b-field>
            </div>
          </div>

            <div class="field is-horizontal has-text-centered">
              <div class="field-body">
                <div class="field">
                  <b-field>
                    <b-button
                      @click="addCourseForUser"
                      expanded
                      icon-right="arrow-circle-right"
                      type="is-success"
                    >Add Course</b-button>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { validate, createSnackbar } from '@/common';

export default {
  data() {
    const today = new Date();

    return {
      courseCreated: false,
      minDate: new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1),
      maxDate: new Date(today.getFullYear(), today.getMonth() + 2, today.getDate()),
    };
  },
  methods: {
    ...mapActions(['getAllLocations', 'setLocationId', 'setDatetime', 'addOneDayCourse']),
    createSnackbar,
    updateCourseTrait(e) {
      this.setOneDayTrait(e);
    },
    async updateLocation({ id }) {
      this.setLocationId(id);
    },
    updateDate(e) {
      this.setDatetime(e);
    },
    async addCourseForUser() {
      const invalidFields = validate({
        location: this.locationId,
        datetime: this.datetime,
      });

      if (!invalidFields.length) {
        try {
          await this.addOneDayCourse();
          this.courseCreated = true;
          if (!this.$route.query.o) {
            this.$router.push(
              {
                name: 'Order',
                query: {
                  u: this.userId,
                  o: this.orderId,
                },
              },
            );
          }

          return this.createSnackbar('One Day Course has been added', true);
        } catch (error) {
          window.scrollTo(0, 0);
          console.error('Error while adding course', error);
          return this.createSnackbar(
            'Please enter correct details to continue',
          );
        }
      } else {
        window.scrollTo(0, 0);
        return this.createSnackbar(`Error in ${invalidFields[0]}`);
      }
    },
  },
  computed: {
    ...mapState({
      trade: (state) => state.oneDayCourseDetails.trade,
      locations: (state) => state.oneDayCourseDetails.locations,
      locationId: (state) => state.oneDayCourseDetails.locationId,
      status: (state) => state.oneDayCourseDetails.status,
      datetime: (state) => state.oneDayCourseDetails.datetime,
      comment: (state) => state.oneDayCourseDetails.comment,
      userId: (state) => state.personalDetails.userId,
      orderId: (state) => state.orderDetail.orderId,
    }),
    getLocation() {
      if (this.location?.length > 0) {
        return this.locations.find(({ id }) => id === this.locationId).name;
      }
      return null;
    },
  },
};
</script>
