<template>
  <div class="container">
    <div class="columns is-centered has-text-left mb-6">
      <div class="column is-three-quarters">
       <nav class="level">
          <!-- Left side -->
          <div class="level-left">
            <div class="level-item">
               <h1 class="title">
                  Test Detail
              </h1>
            </div>
          </div>

          <!-- Right side -->
          <div class="level-right">
            <p class="level-item">
              <b-button
                @click.prevent="() => confirmTask(test.id)"
                icon-right="sync"
                type="is-info"
              >
                Book Test
              </b-button>
            </p>
            <p class="level-item">
              <b-button
                @click.prevent="() => confirmEmail(test.id)"
                icon-right="sync"
                type="is-secondary"
              >
                Email Confirmation
              </b-button>
            </p>
          </div>
        </nav>
      </div>
    </div>
    <div class="columns is-centered has-text-left">
      <div class="column is-three-quarters">
        <div class="field is-horizontal">
          <div class="field-label is-normal has-text-left">
            <label class="label">Test Type</label>
          </div>
          <div class="field-body">
            <div class="field">
              <b-field>
                <b-select
                  @input="updateTestType"
                  @focus="() => setTestId(test.id)"
                  :value="test.testType"
                  id="test-type"
                  ref="test-type"
                  placeholder="Test Type"
                  icon="desktop"
                  expanded
                  required
                >
                  <option value="Operative">Operative</option>
                  <option value="Plumbing or Gas Test">
                    Plumbing or Gas Test
                  </option>
                  <option value="Working at Height Test">
                    Working at Height Test
                  </option>
                  <option value="Supervisors Test">Supervisors Test</option>
                  <option value="Managers and Professionals">
                    Managers and Professionals
                  </option>
                  <option value="Highway Works Test">Highway Works Test</option>
                  <option value="Demolition Test">Demolition Tes</option>
                  <option value="HVACR Test (Domestic Heating and Plumbing)">
                    HVACR Test (Domestic Heating and Plumbing)
                  </option>
                  <option value="HVACR Test (Ductwork)">
                    HVACR Test (Ductwork)
                  </option>
                  <option value="HVACR Test (Pipelifting and Welding)">
                    HVACR Test (Pipelifting and Welding)
                  </option>
                  <option
                    value="HVACR Test (Refrigeration and Air Conditioning)"
                  >
                    HVACR Test (Refrigeration and Air Conditioning)
                  </option>
                  <option value="HVACR Test (Services and Facilities)">
                    HVACR Test (Services and Facilities)
                  </option>
                  <option value="Lift and Escalators Test">
                    Lift and Escalators Test
                  </option>
                  <option value="Tunnelling Test">Tunnelling Test</option>
                </b-select>
              </b-field>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal has-text-left">
            <label class="label">Test Center Location</label>
          </div>
          <div class="field-body">
            <div class="field">
              <b-field>
                <b-select
                  @input="updateLocation"
                  @focus="() => setTestId(test.id)"
                  :value="test.location"
                  id="location"
                  ref="location"
                  icon="desktop"
                  expanded
                >
                  <option v-for="center in testCenters" :value="center.name" :key="center.name">
                    {{center.name}}
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-body">
            <div class="field">
              <b-field>
                <b-datepicker
                  placeholder="Click to select date..."
                  id="date"
                  ref="date"
                  icon="calendar-today"
                  @input="updateDate"
                  :value="new Date(test.date)"
                  :date-formatter="
                    (date) => new Intl.DateTimeFormat('in-IN').format(date)
                  "
                  @focus="() => setTestId(test.id)"
                  trap-focus
                >
                </b-datepicker>
              </b-field>
            </div>
            <div class="field">
              <b-field>
                <b-input
                  placeholder="Preferred Time"
                  id="time"
                  ref="time"
                  icon="clock"
                  @input="updateTime"
                  :value="test.testTime"
                  @focus="() => setTestId(test.id)"
                  expanded
                  required
                >
                </b-input>
              </b-field>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
            <div class="field-label is-normal has-text-left">
              <label class="label">Preferred Language</label>
            </div>
            <div class="field-body">
              <div class="field">
                <b-field>
                  <b-select
                    placeholder="Any special language preference"
                    id="language"
                    ref="language"
                    @input="updateLanguage"
                    :value="test.language"
                    @focus="() => setTestId(test.id)"
                    expanded
                  >
                    <option value="English">English</option>
                    <option value="Bulgarian">Bulgarian</option>
                    <option value="German">German</option>
                    <option value="Lithunian">Lithunian</option>
                    <option value="Polish">Polish</option>
                    <option value="Portuguese">Portuguese</option>
                    <option value="Punjabi">Punjabi</option>
                    <option value="Romanian">Romanian</option>
                    <option value="Russian">Russian</option>
                    <option value="Hungarian">Hungarian</option>
                    <option value="Czetch">Czetch</option>
                    <option value="Voice English">Voice Over English</option>
                    <option value="French">French</option>
                    <option value="Spanish">Spanish</option>
                  </b-select>
                </b-field>
              </div>
            </div>
          </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal has-text-left">
            <label class="label">Status</label>
          </div>
          <div class="field-body">
            <div class="field">
              <b-field>
                <b-select
                  @input="updateTestStatus"
                  :value="test.status"
                  @focus="() => setTestId(test.id)"
                  id="status"
                  ref="status"
                  expanded
                >
                  <option value="Open">Open</option>
                  <option value="To Be Booked">To Be Booked</option>
                  <option value="To be booked">To be booked(old; do not set)</option>
                  <option value="Booked">Booked</option>
                  <option value="Dispute">Dispute</option>
                  <option value="Rebooked">Rebooked</option>
                  <option value="Duplicate">Duplicate</option>
                  <option value="Cancelled">Cancelled</option>
                </b-select>
              </b-field>
            </div>
          </div>
        </div>

        <div class="field is-horizontal" id="test-retake">
          <div class="field-label is-normal has-text-left">
            <label class="label">Test+Retake</label>
          </div>
          <div class="field-body">
            <div class="field">
              <b-checkbox
                :value="test.retake"
                @input="updateRetake"
                @focus="() => setTestId(test.id)"
              >
                Opt for retake
              </b-checkbox>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal has-text-left">
            <label class="label">Comment</label>
          </div>
          <div class="field-body">
            <div class="field">
              <b-field>
                <b-input
                  @input="updateConfirmationText"
                  id="comment"
                  ref="comment"
                  :value="test.confirmationText"
                  @focus="() => setTestId(test.id)"
                  type="textarea"
                ></b-input>
              </b-field>
            </div>
          </div>
        </div>

        <div class="field has-text-centered">
          <b-button
            @click.prevent="testSubmit"
            expanded
            icon-right="arrow-circle-right"
            type="is-success"
          >
            Submit Test
          </b-button>
          <br>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { getHumanDate } from '@/common';
import { centers } from '@/assets/data';

export default {
  name: 'Test',
  data: () => ({
    testTypeDisabled: true,
    locationDisabled: true,
    testCenters: centers,
    updatedTestFields: {},
  }),
  props: ['test'],
  methods: {
    ...mapActions(['setTestId', 'updateTestDetails', 'bookTest', 'sendEmail']),
    getHumanDate,
    confirmTask(testId) {
      this.$buefy.dialog.confirm({
        message: 'Continue on this task?',
        onConfirm: () => this.requestBooking(testId),
      });
    },
    confirmEmail(testId) {
      this.$buefy.dialog.confirm({
        message: 'Send email confirmation for test?',
        onConfirm: () => this.initiateEmail(testId),
      });
    },
    unsetTestTypeDisabled() {
      this.testTypeDisabled = false;
    },
    unsetLocationDisabled() {
      this.locationDisabled = false;
    },
    updateLocation(e) {
      this.updatedTestFields.location = e;
    },
    updateTestType(e) {
      this.updatedTestFields.testType = e;
    },
    updateDate(e) {
      this.updatedTestFields.date = e;
    },
    updateTime(e) {
      this.updatedTestFields.testTime = e;
    },
    updateRetake(e) {
      this.updatedTestFields.retake = e;
    },
    updateTestStatus(e) {
      this.updatedTestFields.status = e;
    },
    updateConfirmationText(e) {
      this.updatedTestFields.confirmationText = e;
      console.log('COnfirmation', e);
    },
    updateLanguage(e) {
      this.updatedTestFields.language = e;
    },
    createSnackbar(message, success) {
      return this.$buefy.toast.open({
        message,
        type: success ? 'is-success' : 'is-warning',
        duration: 3500,
        container: 'section',
        position: 'is-top',
      });
    },
    async testSubmit() {
      try {
        await this.updateTestDetails(this.updatedTestFields);
        return this.createSnackbar('Test Detail updated', true);
      } catch (error) {
        console.error(error);
        return this.createSnackbar('Error while updating details');
      } finally {
        window.scrollTo(0, 0);
      }
    },
    async requestBooking(testId) {
      try {
        await this.bookTest(testId);
        return this.createSnackbar('Test Submitted for booking', true);
      } catch (error) {
        console.error(error);
        return this.createSnackbar('Error while updating details');
      }
    },
    async initiateEmail(testId) {
      try {
        await this.sendEmail(testId);
        return this.createSnackbar('Test Submitted for booking', true);
      } catch (error) {
        console.error(error);
        return this.createSnackbar('Error while updating details');
      }
    },
  },
};
</script>
