<template>
  <section class="section" id="add-test">
    <div v-if="testCreated">
      <h1>Test in {{ location }} for {{ type }} created</h1>
    </div>
    <div v-if="!testCreated">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-three-quarters">
            <h1 class="title">Test Details</h1>
          </div>
        </div>
        <div class="columns is-centered has-text-left">
          <div class="column is-three-quarters">
            <div class="field is-horizontal">
              <div class="field-label is-normal has-text-left">
                <label class="label">Test Type</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <b-field>
                    <b-select
                      @input="updateTestType"
                      :value="type"
                      id="test-type"
                      ref="test-type"
                      placeholder="Test Type"
                      icon="desktop"
                      expanded
                      required
                    >
                      <option value="Operative">Operative</option>
                      <option value="Plumbing or Gas Test">
                        Plumbing or Gas Test
                      </option>
                      <option value="Working at Height Test">
                        Working at Height Test
                      </option>
                      <option value="Supervisors Test">Supervisors Test</option>
                      <option value="Managers and Professionals">
                        Managers and Professionals
                      </option>
                      <option value="Highway Works Test">
                        Highway Works Test
                      </option>
                      <option value="Demolition Test">Demolition Tes</option>
                      <option
                        value="HVACR Test (Domestic Heating and Plumbing)"
                      >
                        HVACR Test (Domestic Heating and Plumbing)
                      </option>
                      <option value="HVACR Test (Ductwork)">
                        HVACR Test (Ductwork)
                      </option>
                      <option value="HVACR Test (Pipelifting and Welding)">
                        HVACR Test (Pipelifting and Welding)
                      </option>
                      <option
                        value="HVACR Test (Refrigeration and Air Conditioning)"
                      >
                        HVACR Test (Refrigeration and Air Conditioning)
                      </option>
                      <option value="HVACR Test (Services and Facilities)">
                        HVACR Test (Services and Facilities)
                      </option>
                      <option value="Lift and Escalators Test">
                        Lift and Escalators Test
                      </option>
                      <option value="Tunnelling Test">Tunnelling Test</option>
                    </b-select>
                  </b-field>
                </div>
              </div>
            </div>

            <div class="field is-horizontal">
              <div class="field-label is-normal has-text-left">
                <label class="label">Test Center Location</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <b-field>
                    <b-autocomplete
                      v-model="name"
                      id="location"
                      ref="location"
                      :data="filteredDataArray"
                      placeholder="e.g. Ilford"
                      icon="map-marker"
                      clearable
                      @select="updateLocation"
                    >
                      <template slot="empty">No results found</template>
                    </b-autocomplete>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-three-quarters">
            <h1 class="title">Test Slot Details</h1>
          </div>
        </div>

        <div class="columns is-centered has-text-left">
          <div class="column is-three-quarters">
            <div class="field is-horizontal">
              <div class="field-body">
                <div class="field">
                  <b-field>
                    <b-datepicker
                      placeholder="Click to select date..."
                      id="date"
                      ref="date"
                      icon="calendar-today"
                      @input="updateDate"
                      :value="date"
                      trap-focus
                    >
                    </b-datepicker>
                  </b-field>
                </div>
                <div class="field">
                  <b-field>
                    <b-input
                      placeholder="Preferred Time"
                      id="time"
                      ref="time"
                      icon="clock"
                      @input="updateTime"
                      :value="time"
                      expanded
                      required
                    >
                    </b-input>
                  </b-field>
                </div>
              </div>
            </div>

             <div class="field is-horizontal">
            <div class="field-label is-normal has-text-left">
              <label class="label">Preferred Language</label>
            </div>
            <div class="field-body">
              <div class="field">
                <b-field>
                  <b-select
                    placeholder="Any special language preference"
                    id="language"
                    ref="language"
                    @input="updateLanguage"
                    :value="language"
                    expanded
                  >
                    <option value="English">English</option>
                    <option value="Bulgarian">Bulgarian</option>
                    <option value="German">German</option>
                    <option value="Lithunian">Lithunian</option>
                    <option value="Polish">Polish</option>
                    <option value="Portuguese">Portuguese</option>
                    <option value="Punjabi">Punjabi</option>
                    <option value="Romanian">Romanian</option>
                    <option value="Russian">Russian</option>
                    <option value="Hungarian">Hungarian</option>
                    <option value="Czetch">Czetch</option>
                    <option value="Voice English">Voice Over English</option>
                    <option value="French">French</option>
                    <option value="Spanish">Spanish</option>
                  </b-select>
                </b-field>
              </div>
            </div>
          </div>

            <div class="field is-horizontal">
              <div class="field-label is-normal has-text-left">
                <label class="label">Comment</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <b-field>
                    <b-input
                      @input="updateConfirmationText"
                      id="confirmation-text"
                      ref="confirmation-text"
                      :value="confirmationText"
                      type="textarea"
                    ></b-input>
                  </b-field>
                </div>
              </div>
            </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal has-text-left">
              <label class="label">Test+Retake</label>
            </div>
            <div class="field-body">
              <div class="field">
                <b-checkbox :value="retake" @input="updateRetake"
                >Opt for retake</b-checkbox>
              </div>
            </div>
          </div>

            <div class="field is-horizontal has-text-centered">
              <div class="field-body">
                <div class="field">
                  <b-field>
                    <b-button
                      @click="addTestForUser"
                      expanded
                      icon-right="arrow-circle-right"
                      type="is-success"
                    >Add Test To Order</b-button>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { centers } from '@/assets/data';
import { validate } from '@/common';

export default {
  name: 'Test',
  data() {
    return {
      data: centers,
      name: '',
      testCreated: false,
    };
  },
  methods: {
    ...mapActions([
      'setLocation',
      'setDate',
      'setTime',
      'setType',
      'setConfirmationText',
      'addTest',
      'setRetake',
      'setLanguage',
    ]),
    updateLocation(e) {
      this.setLocation(e);
    },
    updateDate(e) {
      this.setDate(e);
    },
    updateTime(e) {
      this.setTime(e);
    },
    updateTestType(e) {
      this.setType(e);
    },
    updateConfirmationText(e) {
      this.setConfirmationText(e);
    },
    updateRetake(e) {
      this.setRetake(e);
    },
    updateLanguage(e) {
      this.setLanguage = e;
    },
    createSnackbar(message, success) {
      return this.$buefy.toast.open({
        message,
        type: success ? 'is-success' : 'is-warning',
        duration: 3500,
        container: 'section',
        position: 'is-top',
      });
    },
    async addTestForUser() {
      window.scrollTo(0, 0);
      const invalidFields = validate({
        locations: this.location,
        date: this.date,
        time: this.time,
        type: this.type,
      });

      if (!invalidFields.length) {
        try {
          await this.addTest();
          this.testCreated = true;
          if (!this.$route.query.o) {
            this.$router.push(
              {
                name: 'Order',
                query: {
                  u: this.userId,
                  o: this.orderId,
                },
              },
            );
          }
          return this.createSnackbar('Test has been added', true);
        } catch (error) {
          console.error('Error while adding course', error);
          return this.createSnackbar(
            'Please enter correct details to continue',
          );
        }
      } else {
        return this.createSnackbar(`Error in ${invalidFields[0]}`);
      }
    },
  },
  computed: {
    ...mapState({
      location: (state) => state.testDetails.location,
      date: (state) => state.testDetails.date,
      time: (state) => state.testDetails.time,
      type: (state) => state.testDetails.type,
      retake: (state) => state.testDetails.retake,
      confirmationText: (state) => state.testDetails.confirmationText,
      language: (state) => state.testDetails.language,
      userId: (state) => state.personalDetails.userId,
      orderId: (state) => state.orderDetail.orderId,
    }),
    filteredDataArray() {
      return this.data
        .map(({ name }) => name)
        .filter(
          (option) => option.toString().toLowerCase().indexOf(this.name.toLowerCase())
            >= 0,
        );
    },
  },
};
</script>
